import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useParams } from 'react-router-dom'
import { Box, Button, Chip, IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import ProjectService from 'api/services/project.service'
import RiskService from 'api/services/risk.service'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import TuneIcon from '@mui/icons-material/Tune'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import DataTable from 'components/DataTable/DataTable'
import DropdownDownload from 'components/DropdownDownload/DropdownDownload'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import { useAuth } from 'hooks/useAuth'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { riskAreas, riskMode, riskStatus, riskUrgency, severityLimits } from 'utils/constants'
import SideModalRiskFilters from 'views/Projects/components/SideModalRiskFilters/SideModalRiskFilters'
import ModalRisksSettings from 'views/Projects/components/ModalRisksSettings/ModalRisksSettings'
import { resetFilters } from '../../../../redux/reducer/riskFiltersManagementSlice'
import ModalRisk from '../../components/ModalRisk/ModalRisk'
import Styles from './_Risks.module.scss'

const Risks = () => {
  const { projectId } = useParams()
  const { project } = useOutletContext()
  const { isSystemObserver } = useAuth()
  const { isProjectObserver } = useProjectRoles()
  const dispatch = useDispatch()
  const filters = useSelector(store => store.riskFiltersManagement)
  const [riskPagination, setRiskPagination] = useState({
    page: 0,
    size: 10,
    search: '',
    sort: '',
    order: ''
  })
  const [totalRisks, setTotalRisks] = useState(0)
  const [searchBarValue, setSearchBarValue] = useState('')
  const [initialLoading, setInitialLoading] = useState(true)

  const [alert, setAlert] = useState({ show: false, severity: '', message: '' })
  const [risksList, setRisksList] = useState([])
  const [selectedRisk, setSelectedRisk] = useState()
  const [sidemodalRisk, setSidemodalRisk] = useState({ show: false, actionType: '' })
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })
  const [riskProbabilities, setRiskProbabilities] = useState()
  const [riskCostImpacts, setRiskCostImpacts] = useState()
  const [riskTimeImpacts, setRiskTimeImpacts] = useState()
  const [risksStats, setRisksStats] = useState({ low: 0, high: 0, medium: 0 })
  const [isAllLoaded, setIsAllLoaded] = useState(false)
  const [showSideModalFilters, setShowSideModalFilters] = useState(false)
  const [showModalRisksSettings, setShowModalRisksSettings] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const handleActions = (type, value) => {
    setSelectedRisk(value)
    if (type === 'edit' || type === 'view') {
      setSidemodalRisk({ show: true, actionType: type === 'edit' ? 'update' : 'view' })
    } else if (!isSystemObserver && !isProjectObserver) {
      setModalDelete({ show: true, loading: false })
    }
  }

  const headers = [
    {
      header: 'Severidad',
      key: 'severity_value',
      ordering: true,
      cellType: 'chip'
    },
    {
      header: 'ID',
      key: 'id_by_project',
      cellType: 'text'
    },
    {
      header: 'Fecha registro',
      key: 'creation_date',
      ordering: true,
      cellType: 'date'
    },
    {
      header: 'Titulo',
      key: 'title',
      ordering: true,
      cellType: 'text'
    },
    {
      header: 'Fase',
      key: 'phase_id',
      cellType: 'text'
    },
    {
      header: 'Área (EDR)',
      key: 'risk_area_id',
      cellType: 'text'
    },
    {
      header: 'Fecha límite',
      key: 'deadline_date',
      cellType: 'date'
    },
    {
      header: 'Días restantes',
      key: 'deadline_days',
      cellType: 'text'
    },
    {
      header: 'Riesgo / Problema',
      key: 'mode',
      cellType: 'text'
    },
    // {
    //   header: 'Urgencia',
    //   key: 'risk_urgency_id',
    //   cellType: 'text'
    // },
    {
      header: 'Status',
      key: 'risk_status_id',
      cellType: 'text'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]

  const getSeverityColor = value => {
    return value <= severityLimits.low ? '#05de215c' : value <= severityLimits.medium ? '#ffff009e' : '#ff2121ab'
  }

  const orderData = data => {
    const actions = isSystemObserver || isProjectObserver ? 'action_buttons_observer' : 'action_buttons'
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        const value =
          header.key === 'phase_id'
            ? project.phases.find(phase => phase.id === element[header.key]).name
            : header.key === 'risk_area_id'
              ? riskAreas.find(area => area.value === element[header.key]).label
              : header.key === 'mode'
                ? riskMode.find(mode => mode.value === element[header.key]).label
                : header.key === 'risk_urgency_id'
                  ? riskUrgency.find(mode => mode.value === element[header.key]).label
                  : header.key === 'risk_status_id'
                    ? riskStatus.find(mode => mode.value === element[header.key]).label
                    : header.key === 'severity_value'
                      ? { label: element[header.key], color: getSeverityColor(element[header.key]) }
                      : header.key === 'deadline_date' || header.key === 'deadline_days'
                        ? element.has_deadline
                          ? element[header.key]
                          : 'No aplica'
                        : element[header.key]
        const obj = {
          cellType: header.key === 'actions' ? actions : header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element),
          props: {}
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const handleSortTable = header => {
    setRiskPagination(prevState => ({
      ...prevState,
      sort: header,
      order: header !== prevState.sort ? 'asc' : prevState.order === '' || prevState.order === 'asc' ? 'desc' : 'asc'
    }))
  }

  const getRisks = async () => {
    try {
      const resp = await ProjectService.findRisksFilters(projectId, {
        ...filters,
        ...riskPagination,
        page: riskPagination.page + 1
      })
      setInitialLoading(false)
      setRisksList(orderData(resp.data))
      setTotalRisks(resp.total)
      setShowSideModalFilters(false)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Algo salió mal al listar Riesgos, intenta de nuevo más tarde'
      })
    }
  }

  const getRiskProbabilities = async () => {
    const resp = await ProjectService.findRisksProbabilitiesData(projectId)
    setRiskProbabilities(resp)
  }

  const getRiskCostImpacts = async () => {
    const resp = await ProjectService.findRisksCostImpactsData(projectId)
    setRiskCostImpacts(resp)
  }

  const getRiskTimeImpacts = async () => {
    const resp = await ProjectService.findRisksTimeImpactsData(projectId)
    setRiskTimeImpacts(resp)
  }

  const getRisksStats = async () => {
    const resp = await ProjectService.findRisksStats(projectId)
    setRisksStats(resp)
  }

  const handleSaveRisk = result => {
    setSidemodalRisk(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      let message = ''
      if (sidemodalRisk.actionType === 'create') {
        message = 'Riesgo creado con éxito'
      } else {
        message = 'Riesgo guardado con éxito'
      }
      getRisks()
      getRisksStats()
      setAlert({ open: true, severity: 'success', message: message })
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al guardar el Riesgo, intenta de nuevo más tarde'
      })
    }
  }

  const handleSaveRisksSettings = (result, data) => {
    setShowModalRisksSettings(false)
    if (result === 'success') {
      setRiskProbabilities(data.riskProbabilities)
      setRiskCostImpacts(data.riskCostImpacts)
      setRiskTimeImpacts(data.riskTimeImpacts)

      getRisks()
      getRisksStats()
      setAlert({ open: true, severity: 'success', message: 'Configuración guardad y aplicada con éxito' })
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al guardar los cambios, intenta de nuevo más tarde'
      })
    }
  }

  const handleCreateRisk = () => {
    setSidemodalRisk({ show: true, actionType: 'create' })
  }

  const handleConfirmDeleteRisk = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await RiskService.delete(selectedRisk.id)
      setModalDelete({ show: false, loading: false })
      getRisks()
      getRisksStats()
      setAlert({ open: true, severity: 'success', message: 'Riesgo eliminado con éxito' })
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar el Riesgo, intenta de nuevo más tarde'
      })
    }
  }

  const handleFilters = async () => {
    setRiskPagination(prevState => ({ ...prevState, page: 0 }))
  }

  useEffect(() => {
    if (riskCostImpacts && riskTimeImpacts && riskProbabilities) {
      setIsAllLoaded(true)
    }
  }, [riskCostImpacts, riskTimeImpacts, riskProbabilities])

  useEffect(() => {
    getRisks()
    getRisksStats()
    getRiskProbabilities()
    getRiskCostImpacts()
    getRiskTimeImpacts()
    dispatch(resetFilters())
  }, [])

  useEffect(() => {
    if (isAllLoaded) {
      getRisks()
    }
  }, [isProjectObserver])

  const [filtersQty, setFiltersQty] = useState(0)

  useEffect(() => {
    let qty = 0
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) qty += 1
      } else if (filters[key] !== null && filters[key] !== undefined) {
        qty += 1
      }
    })
    setFiltersQty(qty)
  }, [filters])

  const handlePageChange = (e, value) => {
    setRiskPagination(prevState => ({ ...prevState, page: value }))
  }

  const handleRowsPerPageChange = e => {
    setRiskPagination(prevState => ({ ...prevState, size: e.target.value, page: 0 }))
  }

  const handleClearSearchBar = () => {
    setSearchBarValue('')
    setRiskPagination(prevState => ({ ...prevState, search: '' }))
  }

  const handleDownload = async format => {
    setDownloading(true)
    try {
      await ProjectService.downloadRisksReport(projectId, {
        ...filters,
        ...riskPagination,
        page: riskPagination.page + 1,
        format
      })
      setDownloading(false)
    } catch (error) {
      setDownloading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!initialLoading) getRisks()
  }, [riskPagination])

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.75rem', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <h2>Riesgos</h2>
          <LightTooltip title="Configuración de Riesgos" arrow>
            <span />
            <IconButton disabled={!isAllLoaded || downloading} onClick={() => setShowModalRisksSettings(true)}>
              <SettingsIcon />
            </IconButton>
          </LightTooltip>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box className={Styles.stats_card}>
            <Box className={Styles.stats_card__header} sx={{ backgroundColor: '#05de215c' }}>
              Bajo
            </Box>
            <Box className={Styles.stats_card__body}>{risksStats.low}</Box>
          </Box>
          <Box className={Styles.stats_card}>
            <Box className={Styles.stats_card__header} sx={{ backgroundColor: '#ffff009e' }}>
              Medio
            </Box>
            <Box className={Styles.stats_card__body}>{risksStats.medium}</Box>
          </Box>
          <Box className={Styles.stats_card}>
            <Box className={Styles.stats_card__header} sx={{ backgroundColor: '#ff2121ab' }}>
              Alto
            </Box>
            <Box className={Styles.stats_card__body}>{risksStats.high}</Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <TextField
            className={Styles.searchbar}
            sx={{
              '& .MuiInputBase-root': {
                paddingLeft: '.5rem',
                paddingRight: '.5rem'
              }
            }}
            size="small"
            placeholder="Buscar por título"
            value={searchBarValue}
            onChange={e => setSearchBarValue(e.target.value)}
            onKeyDown={e =>
              e.key === 'Enter' && setRiskPagination(prevState => ({ ...prevState, search: searchBarValue }))
            }
            InputProps={{
              startAdornment: <SearchIcon sx={{ marginRight: '.25rem', color: '#999' }} />,
              endAdornment: (
                <IconButton className={Styles.show_clear_button} size="small" onClick={handleClearSearchBar}>
                  <ClearIcon />
                </IconButton>
              )
            }}
          />
          <Button
            sx={{ marginLeft: '.5rem' }}
            variant="outlined"
            onClick={() => setRiskPagination(prevState => ({ ...prevState, search: searchBarValue }))}>
            Buscar
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <DropdownDownload
            sx={{ marginRight: '1rem' }}
            handleDownload={handleDownload}
            downloading={downloading}
            disabled={!isAllLoaded}
          />
          <Button
            disabled={!isAllLoaded || downloading}
            variant="outlined"
            color="primary"
            sx={{ marginRight: '1rem' }}
            startIcon={<TuneIcon />}
            onClick={() => setShowSideModalFilters(true)}>
            {!isAllLoaded ? (
              'Cargando'
            ) : (
              <>
                Filtros
                {filtersQty !== 0 && (
                  <Chip
                    label={filtersQty}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: '.5rem', fontSize: '.75rem' }}
                  />
                )}
              </>
            )}
          </Button>
          {!isSystemObserver && !isProjectObserver && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateRisk}
              disabled={!isAllLoaded || downloading}>
              {!isAllLoaded ? 'Cargando' : 'Nuevo Riesgo'}
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable
          headers={headers}
          data={risksList}
          disableEditAction={!isAllLoaded}
          isPaginated
          loading={initialLoading}
          total={totalRisks}
          rowsPerPage={riskPagination.size}
          page={riskPagination.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sortHeaderHandler={handleSortTable}
        />
      </Box>
      <ModalRisk
        projectId={projectId}
        phases={project.phases}
        open={sidemodalRisk.show}
        actionType={sidemodalRisk.actionType}
        selectedRisk={selectedRisk}
        riskProbabilities={riskProbabilities}
        riskCostImpacts={riskCostImpacts}
        riskTimeImpacts={riskTimeImpacts}
        onCancel={() => setSidemodalRisk(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleSaveRisk}
      />
      <ModalRisksSettings
        projectId={projectId}
        open={showModalRisksSettings}
        project={project}
        riskProbabilities={riskProbabilities}
        riskCostImpacts={riskCostImpacts}
        riskTimeImpacts={riskTimeImpacts}
        onCancel={() => setShowModalRisksSettings(false)}
        onConfirm={handleSaveRisksSettings}
      />
      <SideModalRiskFilters
        open={showSideModalFilters}
        phases={project.phases}
        onCancel={() => setShowSideModalFilters(false)}
        onConfirm={handleFilters}
      />
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <ModalDelete
        entity="Riesgo"
        element={selectedRisk}
        open={modalDelete.show}
        onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleConfirmDeleteRisk}
        loading={modalDelete.loading}
      />
    </>
  )
}

export default Risks

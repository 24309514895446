import { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import ProjectService from 'api/services/project.service'
import UserService from 'api/services/user.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import DataTable from 'components/DataTable/DataTable'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import SideModalProject from './components/SideModalProject/SideModalProject'

const Projects = () => {
  const { isSystemObserver } = useAuth()
  const [alert, setAlert] = useState({ show: false, severity: '', message: '' })
  const [loading, setLoading] = useState(true)
  const [projectsList, setProjectsList] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [sidemodalProject, setSidemodalProject] = useState({ show: false, actionType: '' })
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })

  const handleActions = (type, value) => {
    setSelectedProject(value)
    if (type === 'edit') {
      setSidemodalProject({ show: true, actionType: 'update' })
    } else {
      setModalDelete({ show: true, loading: false })
    }
  }

  const headers = [
    {
      header: 'Nombre',
      key: 'name',
      cellType: 'link'
    },
    {
      header: 'Descripción',
      key: 'short_description',
      cellType: 'text'
    },
    {
      header: 'Fecha de inicio',
      key: 'start_date',
      cellType: 'text'
    },
    {
      header: 'Fecha de fin',
      key: 'end_contractual_date',
      cellType: 'text'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]
  if (isSystemObserver) headers.pop()

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        const value = header.key === 'name' ? { label: element[header.key], url: `${element.id}` } : element[header.key]
        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getProjects = async () => {
    try {
      const resp = await UserService.findProjects()
      setProjectsList(orderData(resp))
      setLoading(false)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al listar los proyectos, intenta de nuevo mas tarde'
      })
    }
  }

  const handleSaveProject = result => {
    setSidemodalProject(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      let message = ''
      if (sidemodalProject.actionType === 'create') {
        message = 'Proyecto creado con éxito'
      } else {
        message = 'Proyecto guardado con éxito'
      }
      getProjects()
      setAlert({ open: true, severity: 'success', message: message })
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al guardar el Proyecto, intenta de nuevo más tarde'
      })
    }
  }

  const handleCreateProject = () => {
    setSidemodalProject({ show: true, actionType: 'create' })
  }

  const handleConfirmDeleteProject = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await ProjectService.delete(selectedProject.id)
      setModalDelete({ show: false, loading: false })
      getProjects()
      setAlert({ open: true, severity: 'success', message: 'Proyecto eliminado con éxito' })
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar el Proyecto, intenta de nuevo más tarde'
      })
    }
  }

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <>
      <h1>Mis Proyectos</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" disabled />
        {!isSystemObserver && (
          <Button variant="contained" color="primary" size="small" onClick={handleCreateProject}>
            Nuevo Proyecto
          </Button>
        )}
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable loading={loading} headers={headers} data={projectsList} />
      </Box>
      {sidemodalProject.show && (
        <SideModalProject
          open={sidemodalProject.show}
          actionType={sidemodalProject.actionType}
          selectedProject={selectedProject}
          onCancel={() => setSidemodalProject(prevState => ({ ...prevState, show: false }))}
          onConfirm={handleSaveProject}
        />
      )}
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <ModalDelete
        entity="Proyecto"
        element={selectedProject}
        open={modalDelete.show}
        onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleConfirmDeleteProject}
        loading={modalDelete.loading}
      />
    </>
  )
}

export default Projects

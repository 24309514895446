import { configureStore } from '@reduxjs/toolkit'
import projectManagementSlice from './reducer/projectManagementSlice'
import userManagementSlice from './reducer/userManagementSlice'
import riskFiltersManagementSlice from './reducer/riskFiltersManagementSlice'

const store = configureStore({
  reducer: {
    userManagement: userManagementSlice,
    projectManagement: projectManagementSlice,
    riskFiltersManagement: riskFiltersManagementSlice
  }
})

export default store

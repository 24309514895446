import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './assets/scss/index.scss'
import './assets/fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf'
import './assets/fonts/Urbanist/Urbanist-VariableFont_wght.ttf'
import { ThemeProvider } from '@mui/material'
import theme from 'assets/theme'
import store from './redux/store'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

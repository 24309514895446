import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import { useFormik } from 'formik'
import { Alert, Box, Button, Divider, FormControl, IconButton, Tab, Tabs, TextField } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import PhaseService from 'api/services/phase.service'
import ProjectService from 'api/services/project.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import { useAuth } from 'hooks/useAuth'
import { useDate } from 'hooks/useDate'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { useCurrency } from 'hooks/useCurrency'
import { projectDatesLabelsFormSchema } from 'utils/schemas/projectDatesLabelsFormSchema'
import PhaseData from 'views/Projects/components/PhaseData/PhaseData'
import PhaseDataCosts from 'views/Projects/components/PhaseDataCosts/PhaseDataCosts'
import SideModalPhase from '../../components/SideModalPhase/SideModalPhase'
import { setProject, setProjectPhases } from '../../../../redux/reducer/projectManagementSlice'
import Styles from './_Phases.module.scss'

const Phases = () => {
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  const dispatch = useDispatch()
  const { isSystemObserver } = useAuth()
  const { formatStringCurrency } = useCurrency()
  const { project } = useOutletContext()
  const { isProjectObserver } = useProjectRoles()
  const { formatDate } = useDate()

  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(false)
  const [loadingCostsData, setLoadingCostsData] = useState(false)
  const [isTableEdited, setIsTableEdited] = useState(false)
  const [isCostsTableEdited, setIsCostsTableEdited] = useState(false)
  const [alert, setAlert] = useState({ show: false, severity: '', message: '' })
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })
  const [sidemodalPhase, setSidemodalPhase] = useState({ show: false, actionType: '' })
  const [initialLoading, setInitialLoading] = useState(true)
  const [isGeneralPhase, setIsGeneralPhase] = useState(false)

  const [phase, setPhase] = useState()
  const [weekHeaders, setWeekHeaders] = useState([])
  const [phaseId, setPhaseId] = useState(false)
  const [today, setToday] = useState({ date: dayjs(), week: 0 })
  const [cutWeek, setCutWeek] = useState()
  const [projectUpdatedEndDate, setProjectUpdatedEndDate] = useState()
  const [finalProjectedDate, setFinalProjectedDate] = useState()
  const [finalProjectedCostsDate, setFinalProjectedCostsDate] = useState()
  const [totalWeeksWithProjection, setTotalWeeksWithProjection] = useState([])
  const [totalWeeksWithCostsProjection, setTotalWeeksWithCostsProjection] = useState([])
  const [projectedValues, setProjectedValues] = useState([])
  const [projectedCostValues, setProjectedCostValues] = useState([])
  const [projectedWeeklyEVCostValues, setProjectedWeeklyEVCostValues] = useState([])
  const [lastEstimatedValue, setLastEstimatedValue] = useState('')
  const [hasUpdatedEndDate, setHasUpdatedEndDate] = useState(false)

  const [hasManualDays, setHasManualDays] = useState(false)
  const [SAC, setSAC] = useState()
  const [dateES, setDateES] = useState()
  const [ES, setES] = useState()
  const [AT, setAT] = useState()
  const [TPI, setTPI] = useState()
  const [TEAC, setTEAC] = useState()

  const [hasCostsData, setHasCostsData] = useState(false)
  const [hasRealCostsData, setHasRealCostsData] = useState(false)
  const [BAC, setBAC] = useState()
  const [CPI, setCPI] = useState()
  const [CVCostVariance, setCVCostVariance] = useState()
  // const [SVTimeVariance, setSVTimeVariance] = useState()
  const [EAC, setEAC] = useState()

  const [hasProjectedData, setHasProjectedData] = useState(false)

  const [hitoProgrammedLPO, setHitoProgrammedLPO] = useState({
    date: null,
    value: null
  })
  const [hitoProgrammedTTT, setHitoProgrammedTTT] = useState({
    date: null,
    value: null
  })
  const [hitoPMOProjectionLPO, setHitoPMOProjectionLPO] = useState({
    date: null,
    value: null
  })
  const [hitoPMOProjectionTTT, setHitoPMOProjectionTTT] = useState({
    date: null,
    value: null
  })

  const [estimatedPercentage, setEstimatedPercentage] = useState({})
  const [weeklyPercentage, setWeeklyPercentage] = useState({})
  const [difference, setDifference] = useState({})
  const [spi, setSpi] = useState({})
  const [estimatedDaysLPO, setEstimatedDaysLPO] = useState({})

  const [estimatedPercentageTemp, setEstimatedPercentageTemp] = useState({})
  const [weeklyPercentageTemp, setWeeklyPercentageTemp] = useState({})
  const [differenceTemp, setDifferenceTemp] = useState({})
  const [spiTemp, setSpiTemp] = useState({})
  const [estimatedDaysLPOTemp, setEstimatedDaysLPOTemp] = useState({})

  const [estimatedPV, setEstimatedPV] = useState({})
  const [weeklyEV, setWeeklyEV] = useState({})
  const [realAC, setRealAC] = useState({})
  const [weeklyCpi, setWeeklyCpi] = useState({})

  const [estimatedPVTemp, setEstimatedPVTemp] = useState({})
  const [weeklyEVTemp, setWeeklyEVTemp] = useState({})
  const [realACTemp, setRealACTemp] = useState({})
  const [weeklyCpiTemp, setWeeklyCpiTemp] = useState({})

  const [projectionType, setProjectionType] = useState('ADVANCE')
  const [editLPOLabel, setEditLPOLabel] = useState(false)
  const [editTTTLabel, setEditTTTLabel] = useState(false)
  const [labelEntity, setLabelEntity] = useState('')

  const getDataState = {
    estimated_percentage: {
      data: estimatedPercentage,
      setState: setEstimatedPercentage
    },
    weekly_percentage: {
      data: weeklyPercentage,
      setState: setWeeklyPercentage
    },
    difference: {
      data: difference,
      setState: setDifference
    },
    spi: {
      data: spi,
      setState: setSpi
    },
    estimated_days_lpo: {
      data: estimatedDaysLPO,
      setState: setEstimatedDaysLPO
    },
    estimated_pv: {
      data: estimatedPV,
      setState: setEstimatedPV
    },
    weekly_ev: {
      data: weeklyEV,
      setState: setWeeklyEV
    },
    real_ac: {
      data: realAC,
      setState: setRealAC
    },
    weekly_cpi: {
      data: weeklyCpi,
      setState: setWeeklyCpi
    }
  }

  const getDataTempState = {
    estimated_percentage: {
      data: estimatedPercentageTemp,
      setState: setEstimatedPercentageTemp
    },
    weekly_percentage: {
      data: weeklyPercentageTemp,
      setState: setWeeklyPercentageTemp
    },
    difference: {
      data: differenceTemp,
      setState: setDifferenceTemp
    },
    spi: {
      data: spiTemp,
      setState: setSpiTemp
    },
    estimated_days_lpo: {
      data: estimatedDaysLPOTemp,
      setState: setEstimatedDaysLPOTemp
    },
    estimated_pv: {
      data: estimatedPVTemp,
      setState: setEstimatedPVTemp
    },
    weekly_ev: {
      data: weeklyEVTemp,
      setState: setWeeklyEVTemp
    },
    real_ac: {
      data: realACTemp,
      setState: setRealACTemp
    },
    weekly_cpi: {
      data: weeklyCpiTemp,
      setState: setWeeklyCpiTemp
    }
  }

  const generateProjectedValues = (
    // differenceAvarage,
    startDate,
    teac,
    adjustmentValue,
    cutDate,
    estimatedValues,
    weeks,
    lastRealValue,
    orderDataByKey,
    indexLastRealValue,
    estimatedKey = 'estimated_percentage',
    totalValue = '100.00'
  ) => {
    // estimatedValues.reverse()
    const projectedDate = []
    const startDatePlusTEAC = startDate.add(teac, 'days')

    let currentDateCicle = startDatePlusTEAC
    let i = 0
    const adjustmentHours = 24 * adjustmentValue
    while (currentDateCicle > cutDate) {
      if (i === 0) {
        projectedDate.push(startDatePlusTEAC)
      } else {
        // currentDateCicle = projectedDate[i - 1].subtract(1, 'day')
        currentDateCicle = projectedDate[i - 1].subtract(adjustmentHours, 'hour')
        projectedDate.push(currentDateCicle)
      }
      i++
    }

    let x = 0
    let currentPercentageCicle = estimatedValues[0]
    let weeksCurrentIndex = weeks.length + 1
    const programmedAdvance = []
    while (currentPercentageCicle > lastRealValue) {
      const residuo = x % 7
      if (residuo === 0) {
        weeksCurrentIndex--
        currentPercentageCicle = orderDataByKey[estimatedKey][`week_${weeksCurrentIndex}`]
      } else {
        const lowerValue = (1 - (7 - residuo) / 7) * orderDataByKey[estimatedKey][`week_${weeksCurrentIndex - 1}`]
        const higherValue = ((7 - residuo) / 7) * orderDataByKey[estimatedKey][`week_${weeksCurrentIndex}`]
        currentPercentageCicle = lowerValue + higherValue
      }
      programmedAdvance.push(String(Number(currentPercentageCicle).toFixed(2)))
      x++
    }
    programmedAdvance.pop()

    const totalLength = programmedAdvance.length
    const projectedAdvanceDaily = []
    for (let x = 0; x < totalLength; x++) {
      const obj = {
        date: projectedDate[x],
        value: programmedAdvance[x]
      }
      projectedAdvanceDaily.push(obj)
    }

    let currentCutDateCicle = cutDate
    const projectedDateWeekly = []
    const projectedAdvanceWeekly = []

    projectedDateWeekly.push(currentCutDateCicle)
    projectedAdvanceWeekly.push(lastRealValue)
    projectedAdvanceDaily.reverse()

    while (currentCutDateCicle <= startDatePlusTEAC) {
      const date = currentCutDateCicle.add(1, 'week')
      let valueFound = projectedAdvanceDaily.find(day => day.date >= date)?.value
      if (valueFound) {
        if (!projectedAdvanceWeekly.includes(totalValue)) {
          projectedDateWeekly.push(date)
          projectedAdvanceWeekly.push(valueFound)
        }
      } else if (!projectedAdvanceWeekly.includes(totalValue)) {
        projectedDateWeekly.push(date)
        projectedAdvanceDaily.reverse()
        valueFound = projectedAdvanceDaily.find(day => day.date <= date)?.value
        projectedAdvanceWeekly.push(valueFound)
        projectedAdvanceDaily.reverse()
      }
      currentCutDateCicle = date
    }

    const newWeeks = []
    for (let x = 0; x < indexLastRealValue - 1; x++) {
      newWeeks.push(weeks[x].cutoff_date)
    }

    const finalWeeks = [...newWeeks, ...projectedDateWeekly]

    const emptyArray = []
    for (let i = 0; i < indexLastRealValue - 1; i++) {
      emptyArray.push(null)
    }
    const finalArrayWithProjectedValues = [...emptyArray, ...projectedAdvanceWeekly]

    return { startDatePlusTEAC, finalWeeks, finalArrayWithProjectedValues, projectedAdvanceWeekly }
  }

  const getPhaseData = async id => {
    try {
      const data = await PhaseService.find(id)
      const startDate = dayjs(project.start_date)
      const cutoffDate = dayjs(project.cutoff_date)
      const hasUpdated = project.end_date_updated != null && project.end_contractual_date !== project.end_date_updated
      setHasUpdatedEndDate(hasUpdated)
      const endDate = hasUpdated
        ? dayjs(project.end_date_updated).add(1, 'week')
        : dayjs(project.end_contractual_date).add(1, 'week')
      setProjectUpdatedEndDate(endDate)

      const weeksNumber = endDate.diff(startDate, 'week')
      const orderDataByKey = {
        spi: {},
        estimated_days_lpo: {},
        weekly_percentage: {},
        difference: {},
        estimated_percentage: {}
      }
      const orderCostsByKey = {
        estimated_pv: {},
        weekly_ev: {},
        real_ac: {},
        weekly_cpi: {}
      }
      const weeks = []
      const estimatedValues = []
      const realValues = []
      const estimatedCostsValues = []
      const realCostsValues = []
      const weeklyEVCostsValues = []
      let lastEstimatedValue = ''
      // let difTotal = 0
      // let spiTotal = 0

      const hasCostsData = data.costs_data.length > 0
      setHasCostsData(hasCostsData)
      for (let i = 1; i <= weeksNumber; i++) {
        const weekObj = {
          label: `Sem ${i}`,
          key: `week_${i}`,
          number: i,
          start_date: startDate.add(i - 1, 'week'),
          cutoff_date: cutoffDate.add(i - 1, 'week'),
          end_date: startDate.add(i, 'week')
        }
        weeks.push(weekObj)

        // Validate the current week
        if (today.date.isBetween(weekObj.start_date, weekObj.end_date)) {
          setToday(prevState => ({ ...prevState, week: i }))
        }

        const obj = data.data.find(item => item.week_number === i)

        orderDataByKey.spi[`week_${i}`] = obj?.spi || ''
        orderDataByKey.estimated_days_lpo[`week_${i}`] = obj?.estimated_days_lpo || ''
        orderDataByKey.weekly_percentage[`week_${i}`] = obj?.weekly_percentage || ''
        orderDataByKey.difference[`week_${i}`] = obj?.difference || ''
        orderDataByKey.estimated_percentage[`week_${i}`] = obj?.estimated_percentage || ''

        if (data.name === 'General') {
          estimatedValues.push(obj?.estimated_percentage ? Number(obj?.estimated_percentage) : null)
          realValues.push(obj?.weekly_percentage ? Number(obj?.weekly_percentage) : null)
          // difTotal += obj?.difference ? Number(obj?.difference) : 0
          // spiTotal += obj?.spi ? Number(obj?.spi) : 0

          if (hasCostsData) {
            const costObj = data.costs_data.find(item => item.week_number === i)

            orderCostsByKey.estimated_pv[`week_${i}`] = costObj?.estimated_pv || ''
            orderCostsByKey.weekly_ev[`week_${i}`] = costObj?.weekly_ev || ''
            orderCostsByKey.real_ac[`week_${i}`] = costObj?.real_ac ? formatStringCurrency(costObj.real_ac) : ''
            orderCostsByKey.weekly_cpi[`week_${i}`] =
              costObj?.real_ac && costObj?.weekly_ev ? (costObj.weekly_ev / costObj.real_ac).toFixed(2) : ''

            estimatedCostsValues.push(costObj?.estimated_pv ? Number(costObj?.estimated_pv) : null)
            realCostsValues.push(costObj?.real_ac ? Number(costObj?.real_ac) : null)
            if (costObj?.weekly_ev) {
              weeklyEVCostsValues.push(Number(costObj?.weekly_ev))
            }
          }
        }
        if (i === weeksNumber) {
          lastEstimatedValue = obj?.estimated_percentage ? Number(obj?.estimated_percentage) : 0
        }
      }

      setLastEstimatedValue(lastEstimatedValue)
      setPhase(data)
      setWeekHeaders(weeks)

      setEstimatedPercentage(orderDataByKey.estimated_percentage)
      setWeeklyPercentage(orderDataByKey.weekly_percentage)
      setDifference(orderDataByKey.difference)
      setSpi(orderDataByKey.spi)
      setEstimatedDaysLPO(orderDataByKey.estimated_days_lpo)

      const nearestWeekLPO = weeks.find(week => week.cutoff_date >= dayjs(project.lpo_contractual_date))?.key
      const programmedLPO = {
        date: project.lpo_contractual_date ? dayjs(project.lpo_contractual_date) : null,
        value: orderDataByKey.estimated_percentage[nearestWeekLPO]
      }
      setHitoProgrammedLPO(programmedLPO)
      setHitoProgrammedTTT({
        date: dayjs(project.end_contractual_date),
        value: 100
      })

      const lastRealValue = realValues.reverse().find(e => e !== null && e !== 0)
      const cleanRealValues = realValues.filter(e => e !== null && e !== '')
      const needsProjection =
        cleanRealValues.length > 0 && lastRealValue !== '100.00' && cleanRealValues.length <= estimatedValues.length

      // const differenceAvarage = difTotal / cleanRealValues.length
      // const spiAvarage = spiTotal / cleanRealValues.length
      if (data.data.length > 0 && data.name === 'General' && needsProjection) {
        setEstimatedPV(orderCostsByKey.estimated_pv)
        setWeeklyEV(orderCostsByKey.weekly_ev)
        setRealAC(orderCostsByKey.real_ac)
        setWeeklyCpi(orderCostsByKey.weekly_cpi)

        setIsGeneralPhase(true)

        const nearestEstimatedValue = estimatedValues.reverse().find(e => e <= lastRealValue)
        const indexEstimatedValues =
          estimatedValues.length - estimatedValues.findIndex(value => value === nearestEstimatedValue)
        const indexLastRealValue = realValues.length - realValues.findIndex(value => value === lastRealValue)

        // const nearestEstimatedValue = estimatedValues.find(e => e >= lastRealValue + differenceAvarage)
        // const indexEstimatedValues = estimatedValues.findIndex(value => value === nearestEstimatedValue)
        // const indexLastRealValue = realValues.length - realValues.findIndex(value => value === lastRealValue)

        const cutWeek = weeks[indexLastRealValue - 1]
        setCutWeek(cutWeek)
        const cutDate = cutWeek.cutoff_date // Fecha de corte

        const dateEs = weeks[indexEstimatedValues - 1].cutoff_date
        setDateES(dateEs)

        const es = dateEs.diff(startDate, 'days') + 1
        setES(es)

        const at = cutDate.diff(startDate, 'days') + 1
        setAT(at)

        const tpi = (es / at).toFixed(2)
        setTPI(tpi)

        const hasManualDays = !!project.days_manually
        setHasManualDays(hasManualDays)

        const sac = hasManualDays ? (tpi < 1 ? project.days_manually : project.days_deadline) : project.days_deadline
        setSAC(sac)

        // const percentageDifference = (sac / tpi)
        // const teac = (sac / tpi - ((sac / tpi) * differenceAvarage) / 100).toFixed(2)
        const teac = (sac / tpi).toFixed(2)
        setTEAC(teac)

        // Advance
        const adjustmentProjectedFirst = cutDate
        const adjustmentProjectedSecond = startDate.add(teac, 'days')
        const adjustmentProjectedDays = adjustmentProjectedSecond.diff(adjustmentProjectedFirst, 'days')

        const adjustmentProgrammedFirst = weeks[indexEstimatedValues - 1].cutoff_date
        const adjustmentProgrammedSecond = endDate
        const adjustmentProgrammedDays = adjustmentProgrammedSecond.diff(adjustmentProgrammedFirst, 'days')

        const adjustmentValue = adjustmentProjectedDays / adjustmentProgrammedDays

        const { startDatePlusTEAC, finalWeeks, finalArrayWithProjectedValues, projectedAdvanceWeekly } =
          generateProjectedValues(
            // differenceAvarage,
            startDate,
            teac,
            adjustmentValue,
            cutDate,
            estimatedValues,
            weeks,
            lastRealValue,
            orderDataByKey,
            indexLastRealValue,
            'estimated_percentage',
            '100.00'
          )
        setFinalProjectedDate(startDatePlusTEAC)
        setTotalWeeksWithProjection(finalWeeks)
        setProjectedValues(finalArrayWithProjectedValues)

        /**
         * Inicia cálculo de grafica de costos
         */
        const lastRealCostsValue = realCostsValues.reverse().find(e => e !== null && e !== 0 && e !== 0.0)
        const hasRealCostsData = lastRealCostsValue !== undefined
        setHasRealCostsData(hasRealCostsData)
        if (hasCostsData && hasRealCostsData) {
          const indexLastRealCostsValue =
            realCostsValues.length - realCostsValues.findIndex(value => value === lastRealCostsValue)

          const cutWeekCosts = weeks[indexLastRealCostsValue - 1]

          if (cutWeekCosts) {
            // const cutEstimatedPV = orderCostsByKey.estimated_pv[cutWeekCosts.key] // Costo programado al corte
            const cutweeklyEV = orderCostsByKey.weekly_ev[cutWeekCosts.key] // Costo programado al corte
            const cutRealAC = orderCostsByKey.real_ac[cutWeekCosts.key] // Costo programado al corte

            const bac = project.amount_approved
            setBAC(bac)

            const cpi = cutweeklyEV / Number(cutRealAC.replaceAll(',', ''))
            setCPI(cpi)

            const cvCostVariance = cutweeklyEV - Number(cutRealAC.replaceAll(',', ''))
            setCVCostVariance(cvCostVariance)

            // const svTimeVariance = cutweeklyEV / cutEstimatedPV
            // setSVTimeVariance(svTimeVariance)

            const eac = bac / cpi
            setEAC(eac)

            const projectedCostAdvanceWeekly = projectedAdvanceWeekly.map(item => (eac * item) / 100)
            const emptyArrayCosts = []
            for (let i = 0; i < indexLastRealCostsValue - 1; i++) {
              emptyArrayCosts.push(null)
            }

            const finalArrayWithProjectedCostValues = [...emptyArrayCosts, ...projectedCostAdvanceWeekly]

            setFinalProjectedCostsDate(startDatePlusTEAC)
            setTotalWeeksWithCostsProjection(finalWeeks)
            setProjectedCostValues(finalArrayWithProjectedCostValues)

            const finalArrayWithProjectedWeeklyEVValues = finalArrayWithProjectedValues.map(item =>
              item ? (bac * item) / 100 : null
            )
            setProjectedWeeklyEVCostValues(finalArrayWithProjectedWeeklyEVValues)
          }
        }

        if (programmedLPO.date) {
          let useProjections = false
          const newRealValues = [...realValues]
          newRealValues.reverse()
          let nearestIndexLPO = newRealValues.findIndex(value => Number(value) >= Number(programmedLPO.value))
          if (nearestIndexLPO === -1) {
            nearestIndexLPO = finalArrayWithProjectedValues.findIndex(
              value => Number(value) >= Number(programmedLPO.value)
            )
            useProjections = true
          }
          const projectedLPO = {
            date: finalWeeks[nearestIndexLPO],
            value: useProjections ? finalArrayWithProjectedValues[nearestIndexLPO] : newRealValues[nearestIndexLPO]
          }
          setHitoPMOProjectionLPO(projectedLPO)
          setHitoPMOProjectionTTT({
            date: finalWeeks[finalWeeks.length - 1],
            value: 100
          })
        }

        if (!hasManualDays) {
          setAlert({
            open: true,
            severity: 'warning',
            message: 'No se ha ingresado un valor para plazo en días manuales'
          })
        }
        setHasProjectedData(true)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Returns a new Object with values from current state
   * @param {String} key
   * @returns {Object}
   */
  const getCurrentValues = data => {
    const newObject = {}
    Object.keys(data).forEach(key => {
      newObject[key] = data[key]
    })
    return newObject
  }

  const handleChangePhaseTabs = value => {
    setLoading(true)
    setPhaseId(value)
    setHasProjectedData(false)
    setIsGeneralPhase(false)
    getPhaseData(value)
  }

  const handleChangeCostsRowValue = (value, week) => {
    setRealAC(prevSate => ({ ...prevSate, [week]: value }))
    if (value === '') {
      setWeeklyCpi(prevState => ({ ...prevState, [week]: '' }))
    } else {
      const cpiValue = Number(weeklyEV[week]) / Number(value.replaceAll(',', ''))
      setWeeklyCpi(prevState => ({
        ...prevState,
        [week]: Number.isFinite(cpiValue) && cpiValue !== 'NaN' ? cpiValue.toFixed(2) : ''
      }))
    }
  }

  const handleChangeRowValue = (value, key, week) => {
    getDataState[key].setState(prevSate => ({ ...prevSate, [week]: value }))

    if (key === 'estimated_percentage') {
      setEstimatedPV(prevSate => ({
        ...prevSate,
        [week]: value === '' ? '' : (Number(value) * project.amount_approved) / 100
      }))
      if (value === '') {
        setDifference(prevState => ({ ...prevState, [week]: '' }))
        setSpi(prevState => ({ ...prevState, [week]: '' }))
      } else if (weeklyPercentage[week] !== '') {
        const spiValue = Number(weeklyPercentage[week]) / Number(value)
        const diff = Number(weeklyPercentage[week]) - Number(value)
        setDifference(prevState => ({
          ...prevState,
          [week]: Number.isFinite(diff) && diff !== 'NaN' ? diff.toFixed(2) : ''
        }))
        setSpi(prevState => ({
          ...prevState,
          [week]: Number.isFinite(spiValue) && spiValue !== 'NaN' ? spiValue.toFixed(2) : ''
        }))
      }
    } else if (key === 'weekly_percentage') {
      setWeeklyEV(prevSate => ({
        ...prevSate,
        [week]: value === '' ? '' : (Number(value) * project.amount_approved) / 100
      }))
      if (value === '') {
        setDifference(prevState => ({ ...prevState, [week]: '' }))
        setSpi(prevState => ({ ...prevState, [week]: '' }))
      } else if (estimatedPercentage[week] !== '') {
        const spiValue = Number(value) / Number(estimatedPercentage[week])
        const diff = Number(value) - Number(estimatedPercentage[week])
        setDifference(prevState => ({
          ...prevState,
          [week]: Number.isFinite(diff) && diff !== 'NaN' ? diff.toFixed(2) : ''
        }))
        setSpi(prevState => ({
          ...prevState,
          [week]: Number.isFinite(spiValue) && spiValue !== 'NaN' ? spiValue.toFixed(2) : ''
        }))
      }
    }
  }

  const handleCancelEditTable = () => {
    setIsTableEdited(false)

    setEstimatedPercentage(getCurrentValues(estimatedPercentageTemp))
    setWeeklyPercentage(getCurrentValues(weeklyPercentageTemp))
    setDifference(getCurrentValues(differenceTemp))
    setSpi(getCurrentValues(spiTemp))
    setEstimatedDaysLPO(getCurrentValues(estimatedDaysLPOTemp))

    setEstimatedPV(getCurrentValues(estimatedPVTemp))
    setWeeklyEV(getCurrentValues(weeklyEVTemp))
  }

  const handleEditTable = () => {
    setIsTableEdited(true)

    setEstimatedPercentageTemp(getCurrentValues(estimatedPercentage))
    setWeeklyPercentageTemp(getCurrentValues(weeklyPercentage))
    setDifferenceTemp(getCurrentValues(difference))
    setSpiTemp(getCurrentValues(spi))
    setEstimatedDaysLPOTemp(getCurrentValues(estimatedDaysLPO))

    setEstimatedPVTemp(getCurrentValues(estimatedPV))
    setWeeklyEVTemp(getCurrentValues(weeklyEV))
  }

  const handleCancelEditCostsTable = () => {
    setIsCostsTableEdited(false)

    setRealAC(getCurrentValues(realACTemp))
    setWeeklyCpi(getCurrentValues(weeklyCpiTemp))
  }

  const handleEditCostsTable = () => {
    setIsCostsTableEdited(true)

    setRealACTemp(getCurrentValues(realAC))
    setWeeklyCpiTemp(getCurrentValues(weeklyCpi))
  }

  const getRowValue = (key, data, dataTemp) => {
    const array = []

    Object.entries(data).forEach(([week, value]) => {
      const weekNumber = Number(week.replace('week_', ''))
      const dataElement = phase.data.find(item => item.week_number === weekNumber)
      if (!dataElement) {
        if (value !== '') {
          const obj = {
            week_number: weekNumber,
            isCreation: true,
            [key]: String(value).replaceAll(',', ''),
            key: key
          }
          array.push(obj)
        }
      } else if (dataTemp[week] !== value) {
        const obj = {
          week_number: weekNumber,
          isCreation: false,
          [key]: String(value).replaceAll(',', ''),
          key: key,
          id: dataElement.id
        }
        array.push(obj)
      }
    })
    return array
  }

  const handleSaveRow = async (key, array) => {
    try {
      const phaseDataSaved = await PhaseService.savePhaseData(phaseId, { items: array })
      const dataUpdated = phase.data.map(item => {
        const element = phaseDataSaved.data_updated.find(data => data.id === item.id)
        if (element) return element
        return item
      })
      const elementsConcated = dataUpdated.concat(phaseDataSaved.data_created)
      setPhase(prevState => ({
        ...prevState,
        data: elementsConcated
      }))
      getDataTempState[key].setState(getCurrentValues(getDataState[key].data))
      return true
    } catch (error) {
      getDataState[key].setState(getCurrentValues(getDataTempState[key].data))
      console.log(error)
      return false
    }
  }

  const handleSaveCostRow = async (key, array) => {
    try {
      const phaseCostDataSaved = await PhaseService.savePhaseCostData(phaseId, { items: array })
      const dataUpdated = phase.costs_data.map(item => {
        const element = phaseCostDataSaved.data_updated.find(data => data.id === item.id)
        if (element) return element
        return item
      })
      const elementsConcated = dataUpdated.concat(phaseCostDataSaved.data_created)
      setPhase(prevState => ({
        ...prevState,
        costs_data: elementsConcated
      }))
      getDataTempState[key].setState(getCurrentValues(getDataState[key].data))
      return true
    } catch (error) {
      getDataState[key].setState(getCurrentValues(getDataTempState[key].data))
      console.log(error)
      return false
    }
  }

  const handleSaveTableData = async () => {
    setLoadingData(true)

    const estimatedPercentageData = getRowValue('estimated_percentage', estimatedPercentage, estimatedPercentageTemp)
    const weeklyPercentageData = getRowValue('weekly_percentage', weeklyPercentage, weeklyPercentageTemp)
    const differenceData = getRowValue('difference', difference, differenceTemp)
    const spiData = getRowValue('spi', spi, spiTemp)
    const estimatedDaysData = getRowValue('estimated_days_lpo', estimatedDaysLPO, estimatedDaysLPOTemp)

    if (estimatedPercentageData.length > 0) {
      await handleSaveRow('estimated_percentage', estimatedPercentageData)
      const estimatedPVData = getRowValue('estimated_pv', estimatedPV, estimatedPVTemp)
      await handleSaveCostRow('estimated_pv', estimatedPVData)
    }

    if (weeklyPercentageData.length > 0) {
      await handleSaveRow('weekly_percentage', weeklyPercentageData)
      const weeklyEVData = getRowValue('weekly_ev', weeklyEV, weeklyEVTemp)
      await handleSaveCostRow('weekly_ev', weeklyEVData)
    }

    differenceData.length > 0 && (await handleSaveRow('difference', differenceData))
    spiData.length > 0 && (await handleSaveRow('spi', spiData))
    estimatedDaysData.length > 0 && (await handleSaveRow('estimated_days_lpo', estimatedDaysData))

    getPhaseData(phaseId)

    setIsTableEdited(false)
    setLoadingData(false)
  }

  const handleSaveCostsTableData = async () => {
    setLoadingCostsData(true)

    const realACData = getRowValue('real_ac', realAC, realACTemp)
    if (realACData.length > 0) {
      await handleSaveCostRow('real_ac', realACData)
    }

    getPhaseData(phaseId)

    setIsCostsTableEdited(false)
    setLoadingCostsData(false)
  }

  const handleSavePhase = (result, value) => {
    setSidemodalPhase(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      let message = ''
      if (sidemodalPhase.actionType === 'create') {
        message = 'Fase creada con éxito'
        const newPhases = [...project.phases]
        newPhases.push(value)
        dispatch(setProjectPhases(newPhases))
        handleChangePhaseTabs(value.id)
      } else {
        message = 'Fase guardada con éxito'
        getPhaseData(phaseId)
      }
      setAlert({ open: true, severity: 'success', message: message })
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al guardar la Fase, intenta de nuevo más tarde'
      })
    }
  }

  const handleConfirmDeletePhase = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await PhaseService.delete(phaseId)
      setModalDelete({ show: false, loading: false })
      const newPhases = project.phases.filter(item => item.id !== phaseId)
      setPhaseId(false)
      dispatch(setProjectPhases(newPhases))
      setAlert({ open: true, severity: 'success', message: 'Fase eliminada con éxito' })
      handleChangePhaseTabs(project.phases[0].id)
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar la Fase, intenta de nuevo más tarde'
      })
    }
  }

  const handleSaveProjectLabels = async vals => {
    try {
      const resp = await ProjectService.updateDatesLabels(project.id, vals)
      dispatch(setProject({ ...project, lpo_dates_label: resp.lpo_dates_label, ttt_dates_label: resp.ttt_dates_label }))
      setAlert({ open: true, severity: 'success', message: 'Se actualizaron las etiquetas correctamente' })
      setEditLPOLabel(!labelEntity === 'lpo')
      setEditTTTLabel(!labelEntity === 'ttt')
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Ocurrió un error al actualizar las etiquetas, intenta de nuevo más tarde'
      })
    }
  }

  const { values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: {
      lpo_dates_label: project.lpo_dates_label,
      ttt_dates_label: project.ttt_dates_label
    },
    enableReinitialize: true,
    validationSchema: projectDatesLabelsFormSchema,
    onSubmit: vals => {
      handleSaveProjectLabels(vals)
    }
  })

  useEffect(() => {
    if (project) {
      if (project.phases?.length > 0) {
        if (initialLoading) {
          handleChangePhaseTabs(project.phases[0].id)
          setInitialLoading(false)
        }
      }
    }
  }, [project])

  return (
    <>
      <Box>
        <b>Fecha del día:</b> <span>{formatDate(today.date)}</span>
        <b style={{ marginLeft: '2rem' }}>Semana:</b> <span>{today.week}</span> <br />
      </Box>
      <Box sx={{ display: 'flex', borderBottom: '1px solid #ddd', justifyContent: 'space-between' }}>
        {project.phases.length > 0 && (
          <>
            <Tabs
              value={phaseId}
              onChange={(e, value) => handleChangePhaseTabs(value)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ width: '100%' }}>
              {project.phases.map((phase, index) => {
                return <Tab key={index} label={phase.name} value={phase.id} />
              })}
            </Tabs>
            <Box sx={{ alignItems: 'center', marginLeft: '1rem' }}>
              {!isSystemObserver && !isProjectObserver && (
                <Button
                  variant="contained"
                  sx={{ whiteSpace: 'nowrap', height: '40px' }}
                  onClick={() => setSidemodalPhase({ show: true, actionType: 'create' })}>
                  Nueva Fase
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ padding: '1rem 0' }}>
        {loading ? (
          <SkeletonLoading type="phase" />
        ) : (
          <>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '100%', paddingRight: '2rem' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <h2>
                      <b>Fase {phase.name}</b>
                    </h2>
                    {!isSystemObserver && !isProjectObserver && phase.name !== 'General' && (
                      <Box sx={{ marginLeft: '1rem' }}>
                        <IconButton onClick={() => setSidemodalPhase({ show: true, actionType: 'update' })}>
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton onClick={() => setModalDelete({ show: true, loading: false })}>
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <span>{phase.description}</span>
                  <br />
                  <br />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      <b>Fecha de inicio:</b> {formatDate(phase.start_date)}
                    </span>
                    <Box className={`${Styles.dates_marker} ${Styles.dates_start}`} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      <b>Fecha de término:</b> {formatDate(phase.end_date)}
                    </span>
                    <Box className={`${Styles.dates_marker} ${Styles.dates_end}`} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      <b>Fecha del día:</b> {formatDate(today.date)} / <b>Semana actual:</b> {today.week}
                    </span>
                    <Box className={`${Styles.dates_marker} ${Styles.dates_today}`} />
                  </Box>
                  {hasUpdatedEndDate && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <b>Semanas agregadas:</b>{' '}
                        {dayjs(project.end_date_updated).diff(dayjs(project.end_contractual_date), 'weeks')}
                      </span>
                      <Box className={`${Styles.dates_marker} ${Styles.week_added}`} />
                    </Box>
                  )}
                  <br />
                  <Box>
                    <b>Fecha de inicio del proyecto:</b> {formatDate(project.start_date)}
                  </Box>
                  <Box>
                    <b>Fecha final contractual del proyecto:</b> {formatDate(project.end_contractual_date)}
                  </Box>
                  {hasUpdatedEndDate && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <b style={{ marginRight: '.25rem' }}>Fecha final del proyecto actualizada:</b>{' '}
                      {formatDate(project.end_date_updated)}
                      <LightTooltip
                        arrow
                        title="Esta fecha es la suma de la fecha final contractual más las semanas agregadas">
                        <InfoOutlinedIcon sx={{ marginLeft: '.25rem' }} color="info" />
                      </LightTooltip>
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {project.show_lpo_dates === 1 && (
                      <Box className={Styles.hito_card}>
                        {editLPOLabel ? (
                          <Box className={Styles.hito_card__header}>
                            <FormControl sx={{ width: '100%', marginRight: '.4rem' }}>
                              <TextField
                                size="small"
                                variant="outlined"
                                name="lpo_dates_label"
                                value={values.lpo_dates_label}
                                inputProps={{
                                  sx: { padding: '.2rem .5rem !important' }
                                }}
                                error={values.lpo_dates_label === ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </FormControl>
                            Programado
                            <IconButton
                              disabled={values.lpo_dates_label === ''}
                              size="small"
                              sx={{ marginLeft: '.25rem' }}
                              onClick={e => [setLabelEntity('lpo'), handleSubmit(e)]}>
                              <CheckIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={() => setEditLPOLabel(false)}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box className={Styles.hito_card__header}>
                            {project.lpo_dates_label} Programado
                            <IconButton size="small" sx={{ marginLeft: '.5rem' }} onClick={setEditLPOLabel}>
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <Box className={`${Styles.dates_marker} ${Styles.lpo__color}`} />
                          </Box>
                        )}
                        <Box className={Styles.hito_card__body}>
                          <Box className={Styles.hito_card__body_element}>
                            <small>Fecha</small>
                            <b>{hitoProgrammedLPO.date ? formatDate(hitoProgrammedLPO.date) : 'No definida'}</b>
                          </Box>
                          <Box className={Styles.hito_card__body_element}>
                            <small>Avance</small>
                            <b>{hitoProgrammedLPO.value}%</b>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <Box className={Styles.hito_card}>
                      {editTTTLabel ? (
                        <Box className={Styles.hito_card__header}>
                          <FormControl sx={{ width: '100%', marginRight: '.4rem' }}>
                            <TextField
                              size="small"
                              variant="outlined"
                              name="ttt_dates_label"
                              inputProps={{
                                sx: { padding: '.2rem .5rem !important' }
                              }}
                              value={values.ttt_dates_label}
                              error={values.ttt_dates_label === ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </FormControl>
                          Programado
                          <IconButton
                            disabled={values.ttt_dates_label === ''}
                            size="small"
                            sx={{ marginLeft: '.5rem' }}
                            onClick={e => [setLabelEntity('ttt'), handleSubmit(e)]}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setEditTTTLabel(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box className={Styles.hito_card__header}>
                          {editTTTLabel ? values.ttt_dates_label : project.ttt_dates_label} Programado
                          <IconButton size="small" sx={{ marginLeft: '.5rem' }} onClick={() => setEditTTTLabel(true)}>
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                          <Box className={`${Styles.dates_marker} ${Styles.ttt__color}`} />
                        </Box>
                      )}
                      <Box className={Styles.hito_card__body}>
                        <Box className={Styles.hito_card__body_element}>
                          <small>Fecha</small>
                          <b>{formatDate(hitoProgrammedTTT.date)}</b>
                        </Box>
                        <Box className={Styles.hito_card__body_element}>
                          <small>Avance</small>
                          <b>{hitoProgrammedTTT.value}%</b>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {hasProjectedData && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {project.show_lpo_dates === 1 && (
                        <Box className={Styles.hito_card}>
                          <Box className={Styles.hito_card__header}>
                            {editLPOLabel ? values.lpo_dates_label : project.lpo_dates_label} Proyección (TEAC)
                            <Box className={`${Styles.dates_marker} ${Styles.lpo_teac__color}`} />
                          </Box>
                          <Box className={Styles.hito_card__body}>
                            <Box className={Styles.hito_card__body_element}>
                              <small>Fecha</small>
                              <b>{hitoPMOProjectionLPO.date ? formatDate(hitoPMOProjectionLPO.date) : 'No definida'}</b>
                            </Box>
                            <Box className={Styles.hito_card__body_element}>
                              <small>Avance</small>
                              <b>{hitoPMOProjectionLPO.value}%</b>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box className={Styles.hito_card}>
                        <Box className={Styles.hito_card__header}>
                          {editTTTLabel ? values.ttt_dates_label : project.ttt_dates_label} Proyección (TEAC)
                          <Box className={`${Styles.dates_marker} ${Styles.ttt_teac__color}`} />
                        </Box>
                        <Box className={Styles.hito_card__body}>
                          <Box className={Styles.hito_card__body_element}>
                            <small>Fecha</small>
                            <b>{formatDate(hitoPMOProjectionTTT.date)}</b>
                          </Box>
                          <Box className={Styles.hito_card__body_element}>
                            <small>Avance</small>
                            <b>{hitoPMOProjectionTTT.value}%</b>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider sx={{ margin: '.5rem 0rem' }} />
              {isGeneralPhase ? (
                <>
                  <Tabs
                    value={projectionType}
                    onChange={(e, value) => setProjectionType(value)}
                    variant="fullWidth"
                    scrollButtons="auto"
                    sx={{ width: '100%' }}>
                    <Tab label="Proyección de Avance" value="ADVANCE" />
                    <Tab label="Proyección de Costos" value="COSTS" />
                  </Tabs>
                  {projectionType === 'ADVANCE' && (
                    <Box sx={{ marginTop: '1rem' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '100%', lineHeight: '1.25rem' }}>
                          <Box>
                            <b>TPI:</b> {TPI} &nbsp; <small>(Time Performance Index)</small>
                          </Box>
                          <Box>
                            <b>SAC:</b> {SAC} días{' '}
                            {((projectUpdatedEndDate > today.date && finalProjectedDate < today.date) ||
                              !hasManualDays) && <WarningAmberOutlinedIcon color="warning" fontSize="small" />}
                            &nbsp; <small>(Schedule At Completion)</small>
                          </Box>
                          <Box>
                            <b>Fecha ES:</b> {formatDate(dateES)}
                          </Box>
                          <Box>
                            <b>ES:</b> {ES} días &nbsp; <small>(Earned Schedule)</small>
                          </Box>
                          <Box>
                            <b>AT:</b> {AT} días &nbsp; <small>(Actual Time)</small>
                          </Box>
                          <Box>
                            <b>TEAC:</b> {TEAC} días &nbsp; <small>(Time Estimated At Completion)</small>
                          </Box>
                          <Box>
                            <b>Fecha TEAC:</b> {formatDate(finalProjectedDate)} &nbsp;
                            <small>(Fecha estimada de fin)</small>
                          </Box>
                        </Box>
                        <Box>
                          {!hasManualDays && (
                            <Alert severity="warning">
                              No se ha ingresado un valor para <b>Días manuales</b>, por lo tanto se estará tomando
                              siempre como parámetro <b>SAC</b> el plazo original del proyecto
                            </Alert>
                          )}
                          {projectUpdatedEndDate > today.date && finalProjectedDate < today.date && (
                            <Alert severity="warning">
                              No se muestra correctamente la gráfica de proyección debido a que el "SAC" es muy bajo y
                              sumado a la fecha de inicio da como resultado la fecha:{' '}
                              <b>{formatDate(finalProjectedDate)}</b> la cual es menor a la fecha actual y ésto no
                              permite generar un proyección futura, sugerimos actualizar los "Días Manuales" del
                              proyecto a un número mayor (de preferencia cercano a los días de plazo original) para
                              poder representar la gráfica de proyección correctamente
                            </Alert>
                          )}
                        </Box>
                      </Box>
                      <PhaseData
                        difference={difference}
                        estimatedDaysLPO={estimatedDaysLPO}
                        estimatedPercentage={estimatedPercentage}
                        finalProjectedDate={finalProjectedDate}
                        hasUpdatedEndDate={hasUpdatedEndDate}
                        hasProjectedData={hasProjectedData}
                        hitoPMOProjectionLPO={hitoPMOProjectionLPO}
                        hitoPMOProjectionTTT={hitoPMOProjectionTTT}
                        isTableEdited={isTableEdited}
                        lastEstimatedValue={lastEstimatedValue}
                        loadingData={loadingData}
                        phase={phase}
                        projectedValues={projectedValues}
                        spi={spi}
                        totalWeeksWithProjection={totalWeeksWithProjection}
                        today={today}
                        weekHeaders={weekHeaders}
                        weeklyPercentage={weeklyPercentage}
                        handleCancelEditTable={handleCancelEditTable}
                        handleChangeRowValue={handleChangeRowValue}
                        handleEditTable={handleEditTable}
                        handleSaveTableData={handleSaveTableData}
                        setWeekHeaders={setWeekHeaders}
                      />
                    </Box>
                  )}
                  {projectionType === 'COSTS' && (
                    <Box sx={{ marginTop: '1rem' }}>
                      {hasCostsData ? (
                        <>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '100%', lineHeight: '1.25rem' }}>
                              <Box>
                                <b>BAC (Monto total del proyecto en {project.currency}):</b>{' '}
                                {formatStringCurrency(BAC, true)} {project.currency}
                                &nbsp; <small>(Budget At Completion)</small>
                              </Box>
                              <Box>
                                <b>CV Cost Variance</b> {formatStringCurrency(CVCostVariance, true)} {project.currency}
                              </Box>
                              <Box>
                                <b>CPI:</b> {CPI?.toFixed(2)} &nbsp; <small>(Cost Performance Index)</small>
                              </Box>
                              <Box>
                                <b>EAC:</b> {formatStringCurrency(EAC, true)} {project.currency} &nbsp;{' '}
                                <small>(Estimated At Completion)</small>
                              </Box>
                            </Box>
                            <Box>
                              {!hasRealCostsData && (
                                <Alert severity="info">
                                  No se tienen registrado ningun valor de "Costo Real AC", por lo tanto aún no se pueden
                                  calcular los datos de proyección
                                </Alert>
                              )}
                            </Box>
                          </Box>
                          <PhaseDataCosts
                            cutWeek={cutWeek}
                            estimatedPV={estimatedPV}
                            finalProjectedDate={finalProjectedCostsDate}
                            hasUpdatedEndDate={hasUpdatedEndDate}
                            hasProjectedData={hasProjectedData}
                            hitoPMOProjectionLPO={hitoPMOProjectionLPO}
                            hitoPMOProjectionTTT={hitoPMOProjectionTTT}
                            isTableEdited={isCostsTableEdited}
                            lastEstimatedValue={lastEstimatedValue}
                            loadingData={loadingCostsData}
                            phase={phase}
                            projectedCostValues={projectedCostValues}
                            projectedWeeklyEVCostValues={projectedWeeklyEVCostValues}
                            realAC={realAC}
                            today={today}
                            totalWeeksWithProjection={totalWeeksWithCostsProjection}
                            weekHeaders={weekHeaders}
                            weeklyEV={weeklyEV}
                            weeklyCpi={weeklyCpi}
                            handleCancelEditTable={handleCancelEditCostsTable}
                            handleChangeCostsRowValue={handleChangeCostsRowValue}
                            handleChangeRowValue={handleChangeRowValue}
                            handleEditTable={handleEditCostsTable}
                            handleSaveTableData={handleSaveCostsTableData}
                            setWeekHeaders={setWeekHeaders}
                          />
                        </>
                      ) : (
                        <p>No hay datos para mostrar</p>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <PhaseData
                  difference={difference}
                  estimatedDaysLPO={estimatedDaysLPO}
                  estimatedPercentage={estimatedPercentage}
                  finalProjectedDate={finalProjectedDate}
                  hasUpdatedEndDate={hasUpdatedEndDate}
                  hasProjectedData={hasProjectedData}
                  hitoPMOProjectionLPO={hitoPMOProjectionLPO}
                  hitoPMOProjectionTTT={hitoPMOProjectionTTT}
                  isTableEdited={isTableEdited}
                  lastEstimatedValue={lastEstimatedValue}
                  loadingData={loadingData}
                  phase={phase}
                  projectedValues={projectedValues}
                  spi={spi}
                  totalWeeksWithProjection={totalWeeksWithProjection}
                  today={today}
                  weekHeaders={weekHeaders}
                  weeklyPercentage={weeklyPercentage}
                  handleCancelEditTable={handleCancelEditTable}
                  handleChangeRowValue={handleChangeRowValue}
                  handleEditTable={handleEditTable}
                  handleSaveTableData={handleSaveTableData}
                  setWeekHeaders={setWeekHeaders}
                />
              )}
            </Box>
            <SideModalPhase
              actionType={sidemodalPhase.actionType}
              open={sidemodalPhase.show}
              projectId={project.id}
              selectedPhase={phase}
              onCancel={() => setSidemodalPhase(prevState => ({ ...prevState, show: false }))}
              onConfirm={handleSavePhase}
            />
            <ModalDelete
              entity="Fase"
              element={phase}
              open={modalDelete.show}
              onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
              onConfirm={handleConfirmDeletePhase}
              loading={modalDelete.loading}
            />
          </>
        )}
      </Box>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Phases

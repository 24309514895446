import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Button, CircularProgress, Divider, FormControl, TextField } from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import EntityService from 'api/services/entity.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import User from 'models/User'
import { ROLE_ADMIN } from 'utils/constants'
import { entityCreationFormSchema } from 'utils/schemas/userFormSchema'
import Styles from './_Register.module.scss'

const Register = () => {
  const navigate = useNavigate()

  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [loading, setLoading] = useState(false)
  const userForm = {
    entity: '',
    ...new User(),
    role_id: ROLE_ADMIN
  }

  const handleRegister = async entity => {
    try {
      await EntityService.store(entity)
      setAlert({ open: true, severity: 'success', message: 'Registrado con éxito. Redirigiendo...' })
      setTimeout(() => {
        navigate('/login')
      }, 1000)
    } catch (error) {
      setLoading(false)
      const message = error.response?.data?.message.includes('The email has already been taken')
        ? 'Ya hay una cuenta registrada con ese email'
        : 'Algo salió mal, intenta de nuevo más tarde'
      setAlert({ severity: 'error', open: true, message: message })
    }
  }

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: userForm,
    enableReinitialize: true,
    validationSchema: entityCreationFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleRegister(vals)
    }
  })

  useEffect(() => {
    setAlert({ open: false, severity: '', message: '' })
    setLoading(false)
  }, [])

  return (
    <>
      <Box className={Styles.layout}>
        <Box className={Styles.login__card}>
          <Box className={Styles.login__card__img}>
            <img src="Logo_SideMenu.png" alt="" />
          </Box>
          <Box className={Styles.login__card__body}>
            <Box sx={{ textAlign: 'center', marginBottom: '2.5rem' }}>
              <h2 style={{ marginBottom: '1rem' }}>Regístrate</h2>
              <span style={{ color: '#777' }}>Ingresa los datos de tu empresa y regístrate en PMOX Dashboard</span>
            </Box>
            <FormControl className={Styles.input}>
              <Box sx={{ marginBottom: '1rem', fontSize: '.75rem', color: '#888' }}>
                Se generará un entorno de PMOX asociado a tu empresa del cual serás propietario. En éste entorno podrás
                crear tur proyectos y dar de alta a tus colegas
              </Box>
              <TextField
                name="entity"
                label="Nombre de tu empresa o entidad"
                placeholder="Tu empresa S.A. de C.V"
                size="small"
                variant="outlined"
                value={values.entity}
                error={touched?.entity && Boolean(errors.entity)}
                helperText={touched.entity && errors.entity}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <BusinessOutlinedIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <Divider />
            </FormControl>
            <FormControl className={Styles.input}>
              <Box sx={{ marginBottom: '1rem', fontSize: '.75rem', color: '#888' }}>
                Con la siguiente información personal se te creará un usuario "Propietario" de tu entorno PMOX, todos
                los cargos del plan que elijas se asociarán a éste usuario
              </Box>
              <TextField
                name="name"
                label="Nombre"
                placeholder="Ingresa tu nombre"
                size="small"
                variant="outlined"
                value={values.name}
                error={touched?.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <PersonOutlineIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="last_name"
                label="Apellidos"
                placeholder="Ingresa tu o tus apellidos"
                size="small"
                variant="outlined"
                value={values.last_name}
                error={touched?.last_name && Boolean(errors.last_name)}
                helperText={touched.last_name && errors.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <PersonOutlineIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="email"
                label="Email"
                placeholder="Ingresa tu email"
                size="small"
                variant="outlined"
                value={values.email}
                error={touched?.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <EmailOutlinedIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="password"
                label="Contraseña"
                placeholder="Ingresa una contraseña"
                size="small"
                variant="outlined"
                type="password"
                value={values.password}
                error={touched?.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <LockOutlinedIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="password_confirmation"
                label="Contraseña"
                placeholder="Confirma la contraseña"
                size="small"
                variant="outlined"
                type="password"
                value={values.password_confirmation}
                error={touched?.password_confirmation && Boolean(errors.password_confirmation)}
                helperText={touched.password_confirmation && errors.password_confirmation}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <LockOutlinedIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
          </Box>
          <Box className={Styles.login__card__footer}>
            <Button
              sx={{ marginBottom: '2rem', width: '10rem' }}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmit}>
              {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Registrarme'}
            </Button>
            <span>
              Ya tienes una cuenta?{' '}
              <span className={Styles.link} onClick={() => navigate('/login')}>
                Inicia sesión aquí
              </span>
            </span>
          </Box>
        </Box>
      </Box>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Register

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AuthService from 'api/services/auth.service'
import { ROLE_USER_OBSERVER } from 'utils/constants'
import { clearUser, setUser } from '../redux/reducer/userManagementSlice'

export const useAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isSystemObserver = useSelector(store => store.userManagement.user)?.role_id === ROLE_USER_OBSERVER
  const getAuth = async () => {
    try {
      const resp = await AuthService.getCurrentUser()
      dispatch(setUser(resp))
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = async () => {
    try {
      await AuthService.logout()
      localStorage.clear()
      dispatch(clearUser())

      navigate('login')
    } catch (error) {
      navigate('login')
    }
  }

  return { isSystemObserver, handleLogout, getAuth }
}

import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist'
  },
  palette: {
    primary: {
      main: '#092336'
    },
    neutral: {
      main: '#eceff1'
    }
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: 'auto'
          }
        }
      ],
      styleOverrides: {
        root: {
          padding: '4px 20px',
          borderRadius: '20px',
          textTransform: 'none',
          height: '40px',
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 10
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red'
        }
        // root: {
        //   '&. MuiInputLabel-asterisk': {
        //     color: 'red'
        //   },
        //   '&. MuiFormLabel-asterisk': {
        //     color: 'red'
        //   }
        // }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important'
        }
      }
    }
  }
})

export default theme

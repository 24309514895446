import * as Yup from 'yup'

export const riskFormSchema = Yup.object().shape({
  phase_id: Yup.number().required('La Fase es requerida'),
  type: Yup.string().required('El tipo de riesgo es requerido'),
  mode: Yup.string().required('La modalidad es requerida'),
  impact_score: Yup.number().required('El impacto es requerido'),
  probability_score: Yup.number().required('La probabilidad es requerida'),
  severity_value: Yup.number().required('La severidad es requerida'),
  title: Yup.string().required('El título es requerido'),
  creation_date: Yup.string().required('La fecha de registro es requerida'),
  has_deadline: Yup.boolean(),
  deadline_date: Yup.string()
    .nullable()
    .when('$has_deadline', {
      is: true,
      then: schema => schema.required('La fecha límite es requerida')
    }),
  reminder_frequency: Yup.number()
    .nullable()
    .when('$has_deadline', {
      is: true,
      then: schema => schema.required('La frecuencia es requerida')
    }),
  description: Yup.string().required('La descripción es requerida'),
  visible_cause: Yup.string().required('La causa visible es requerida'),
  affectation: Yup.string().required('La afectación es requerida'),
  risk_urgency_id: Yup.number().required('La urgencia es requerida'),
  risk_area_id: Yup.number().required('El área/ámbito es requerido'),
  risk_impact_area_id: Yup.number().required('El área de impacto es requerida')
})

export const risksSettingsSchema = Yup.object().shape({
  riskCostImpacts: Yup.array().of(
    Yup.object().shape({
      expression: Yup.string().required('La expresión no puede quedar vacía'),
      description: Yup.string().required('La descripción no puede quedar vacía'),
      value: Yup.string().required('El valor no puede quedar vacío')
    })
  ),
  riskTimeImpacts: Yup.array().of(
    Yup.object().shape({
      expression: Yup.string().required('La expresión no puede quedar vacía'),
      description: Yup.string().required('La descripción no puede quedar vacía'),
      value: Yup.string().required('El valor no puede quedar vacío')
    })
  ),
  riskProbabilities: Yup.array().of(
    Yup.object().shape({
      expression: Yup.string().required('La expresión no puede quedar vacía'),
      description: Yup.string().required('La descripción no puede quedar vacía'),
      value: Yup.string().required('El valor no puede quedar vacío')
    })
  )
})

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import { Outlet, useNavigate } from 'react-router-dom'
// import Styles from './_Settings.module.scss'

const Settings = () => {
  const navigate = useNavigate()
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const user = useSelector(store => store.userManagement.user)

  const [tabValue, setTabValue] = useState('profile')

  const handleChangeTab = value => {
    setTabValue(value)
    navigate(value)
  }

  useEffect(() => {
    navigate(tabValue)
  }, [])

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <h1>Configuración</h1>
      {user && (
        <Box sx={{ display: 'flex', height: '100%', paddingTop: '1rem' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={(e, value) => handleChangeTab(value)}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', height: '100%', minWidth: '10rem' }}>
            <Tab value="profile" label="Perfil" />
            <Tab value="subscription" label="Subscripción" />
          </Tabs>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
            <Outlet />
          </Box>
        </Box>
      )}
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </Box>
  )
}

export default Settings

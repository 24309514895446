import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import Person from '@mui/icons-material/Person'
import Logout from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu'
import AuthService from 'api/services/auth.service'
import Styles from './_TopMenu.module.scss'
import { clearUser } from '../../../../redux/reducer/userManagementSlice'

const TopMenu = ({ onMenuClick }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const user = useSelector(store => store.userManagement.user)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      await AuthService.logout()
      localStorage.clear()
      dispatch(clearUser())

      navigate('login')
    } catch (error) {
      navigate('login')
    }
  }

  const handleSidebar = () => {
    onMenuClick()
  }

  return (
    <>
      <Box className={Styles.topmenu}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleSidebar}>
            <MenuIcon />
          </IconButton>
          {user && (
            <Box
              sx={{
                marginLeft: '1rem',
                fontSize: '1.2rem',
                backgroundColor: 'aliceblue',
                padding: '.5rem 1rem',
                borderRadius: '.25rem'
              }}>
              {user.entity.name}
            </Box>
          )}
        </Box>
        {user !== 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ borderRight: '1px solid #ddd', padding: '.5rem 2rem' }}>Rol: {user?.role?.name || ''}</Box>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              disableRipple
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <Avatar sx={{ width: 32, height: 32, margin: 1, textTransform: 'uppercase' }}>
                {user ? `${user.name.charAt(0)}${user.last_name?.charAt(0)}` : ''}
              </Avatar>
              {user ? `${user.name} ${user.last_name?.charAt(0)}` : ''}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <MenuItem onClick={() => navigate('profile')}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                Mi Perfil
              </MenuItem>
              {user?.is_owner && (
                <MenuItem onClick={() => navigate('settings')}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  Configuración
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
      {/* {loading && <Loader />} */}
    </>
  )
}

TopMenu.propTypes = {
  onMenuClick: PropTypes.func
}

export default TopMenu

import axios from '../axios'

class EntityService {
  static async store(params) {
    const resp = await axios.post('/entities', params)
    return resp.data
  }
}

export default EntityService

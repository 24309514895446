import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ProjectService from 'api/services/project.service'

const DropdownProjectRoles = ({ enableTags = false, onBlur, onChange, value, error, helperText }) => {
  const [usersList, setProjectRolesList] = useState([])

  const getProjectRoles = async () => {
    try {
      const resp = await ProjectService.findRoles()
      setProjectRolesList(resp)
    } catch (error) {
      console.log(error)
    }
  }
  const tagsData = !enableTags ? { renderTags: () => {} } : null

  useEffect(() => {
    getProjectRoles()
  }, [])

  return (
    <Autocomplete
      {...tagsData}
      limitTags={1}
      sx={{ width: '100%' }}
      multiple
      disableCloseOnSelect
      options={usersList}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderOption={(props, option, { selected }) => (
        <Box {...props} sx={{ padding: '.5rem .75rem !important' }}>
          <Checkbox
            sx={{ padding: 0 }}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label="Roles de proyecto"
          error={error}
          helperText={helperText}
          onBlur={onBlur}
        />
      )}
    />
  )
}

DropdownProjectRoles.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.array,
  enableTags: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

export default DropdownProjectRoles

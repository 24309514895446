import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, TextField } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import User from 'models/User'
import { useDispatch, useSelector } from 'react-redux'
import { setProjectStorageRole, setProjectStorage } from '../../../../redux/reducer/projectManagementSlice'

const DropdownProjectRole = ({ user }) => {
  const { projectId } = useParams()
  const [currentUser, setCurrentUser] = useState(new User())
  const dispatch = useDispatch()
  const currentProject = useSelector(store => store.projectManagement.project_storage)

  const hanldeChange = value => {
    dispatch(setProjectStorageRole(value))
    localStorage.setItem(`project_${projectId}`, JSON.stringify({ id: Number(projectId), project_role: value }))
  }
  useEffect(() => {
    if (user) {
      setCurrentUser({ ...user })
      const project = localStorage.getItem(`project_${projectId}`)
      if (project) {
        dispatch(setProjectStorage(JSON.parse(project)))
      }
    }
  }, [user])

  return (
    <FormControl
      sx={{
        '& fieldset': {
          borderColor: 'transparent',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)'
          }
        }
      }}>
      {currentUser.project_roles.length > 0 && (
        <TextField
          name="role_id"
          sx={{ '&.MuiInputBase-root::before': { border: '0' } }}
          variant="outlined"
          size="small"
          select
          value={currentProject.project_role}
          SelectProps={{
            IconComponent: props => <ExpandMoreIcon {...props} />
          }}
          onChange={e => hanldeChange(e.target.value)}>
          {currentUser.project_roles.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </FormControl>
  )
}

DropdownProjectRole.propTypes = {
  user: PropTypes.object
}

export default DropdownProjectRole

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import UserService from 'api/services/user.service'

const DropdownUsersMultiple = ({
  error,
  label = 'Seleccionar usuarios',
  multiple = true,
  enableTags = false,
  helperText,
  placeholder,
  onBlur,
  onChange,
  value
}) => {
  const [usersList, setUsersList] = useState([])

  const getUsers = async () => {
    try {
      const resp = await UserService.findAllWithProjectRoles()
      setUsersList(resp)
    } catch (error) {
      console.log(error)
    }
  }
  const tagsData = !enableTags ? { renderTags: () => {} } : null

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <Autocomplete
      {...tagsData}
      sx={{ width: '100%' }}
      multiple={multiple}
      disableCloseOnSelect
      options={usersList}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderOption={(props, option, { selected }) => (
        <Box {...props} key={option.id} sx={{ padding: '.5rem .75rem !important' }}>
          <Checkbox
            sx={{ padding: 0 }}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name} {option.last_name}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          label={label}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
        />
      )}
    />
  )
}

DropdownUsersMultiple.propTypes = {
  enableTags: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.array
}

export default DropdownUsersMultiple

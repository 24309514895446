import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs'
import { Alert, Box, Divider, Grid, LinearProgress } from '@mui/material'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ProjectService from 'api/services/project.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import { useCurrency } from 'hooks/useCurrency'
import { useDate } from 'hooks/useDate'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import Styles from './_ProjectDetails.module.scss'

const ProjectDetail = () => {
  const { formatDate } = useDate()
  const { formatStringCurrency } = useCurrency()
  const { project } = useOutletContext()

  const [alert, setAlert] = useState({ show: false, severity: '', message: '' })
  const [loading, setLoading] = useState(true)
  const [phasesData, setPhasesData] = useState([])
  const [loadingPhasesData, setLoadingPhasesData] = useState(false)

  const SPI_COLORS = {
    blue: '#00a4e5',
    green: 'green',
    orange: 'orange',
    red: '#ce1010'
  }

  const getSPIColor = value => {
    const numberValue = Number(value)
    if (numberValue < 0.9) {
      return SPI_COLORS.red
    }
    if (numberValue < 0.95) {
      return SPI_COLORS.orange
    }
    if (numberValue <= 1) {
      return SPI_COLORS.green
    }
    return SPI_COLORS.blue
  }

  const getProjectPhases = async () => {
    setLoadingPhasesData(true)
    try {
      const resp = await ProjectService.findPhases(project.id)
      setPhasesData(resp)
      setLoadingPhasesData(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (project) {
      getProjectPhases()
      setLoading(false)
    }
  }, [project])

  return (
    <>
      {!loading && project ? (
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Box>
                <b>Contrato:</b> {project.contract_number}
              </Box>
              <Box>
                <b>Descripción:</b> {project.large_description}
              </Box>
              <Divider sx={{ margin: '1rem 0' }}>
                <Box sx={{ fontSize: '1.2rem' }}>Plazo</Box>
              </Divider>
              <Box>
                <b>Fecha de inicio:</b> {formatDate(project.start_date)}
              </Box>
              <Box>
                <b>Fecha final contractual:</b> {formatDate(project.end_contractual_date)}
              </Box>
              <br />
              <Box>
                <b>Días de plazo:</b> {project.days_deadline} días
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <b>Días de plazo manual:</b>
                {project.days_manually ? (
                  <>&nbsp;{project.days_manually} días</>
                ) : (
                  <>
                    &nbsp;No definido&nbsp;
                    <LightTooltip title="Te recomendamos definir un plazo igual o cercano a los días de plazo" arrow>
                      <WarningAmberOutlinedIcon color="warning" fontSize="small" />
                    </LightTooltip>
                  </>
                )}
              </Box>
              {project.end_date_updated && project.end_contractual_date !== project.end_date_updated && (
                <>
                  <br />
                  <Alert severity="info">
                    Se agregaron semanas debido a que en alguna de las fases se está tomando mas del plazo original del
                    proyecto para registrar el avance real, por lo tanto hay una nueva fecha de finalización del
                    proyecto
                  </Alert>
                  <br />
                  <Box>
                    <b>Fecha final actualizada:</b> {formatDate(project.end_date_updated)}
                  </Box>
                  <Box>
                    <b>Semanas agregadas:</b>{' '}
                    {dayjs(project.end_date_updated).diff(dayjs(project.end_contractual_date), 'weeks')}
                  </Box>
                </>
              )}
              <Divider sx={{ margin: '1rem 0' }}>
                <Box sx={{ fontSize: '1.2rem' }}>Costos</Box>
              </Divider>
              {project.costs.length > 0 && (
                <Box>
                  <b>Monto del contrato en:</b>
                </Box>
              )}
              {project.costs.map((cost, index) => {
                return (
                  <Box key={index}>
                    - <span style={{ fontWeight: 600 }}>{cost.currency}:</span> $ {formatStringCurrency(cost.amount)}{' '}
                    <small>
                      ($ {formatStringCurrency(cost.amount / cost.exchange)} {project.currency} | Tipo de cambio: $
                      {cost.exchange})
                    </small>
                  </Box>
                )
              })}
              <Box>
                - <span style={{ fontWeight: 600 }}> {project.currency}:</span> $ {formatStringCurrency(project.amount)}
              </Box>
              <Box sx={{ marginTop: '1rem' }}>
                <h3>
                  <b>Monto aprobado en {project.currency}:</b> $ {formatStringCurrency(project.amount_approved)}
                </h3>
              </Box>
              <Divider sx={{ margin: '1rem 0' }}>
                <Box sx={{ fontSize: '1.2rem' }}>Indicadores</Box>
              </Divider>
              <Box>
                <h3>
                  <b>SPI:</b> {phasesData.find(item => item.name === 'General')?.spi || 'No definido'}
                  &nbsp; <small>(Schedule Performance Index)</small>
                </h3>
              </Box>
              <Box>
                <h3>
                  <b>CPI:</b> {phasesData.find(item => item.name === 'General')?.cpi?.toFixed(2) || 'No definido'}
                  &nbsp; <small>(Cost Performance Index)</small>
                </h3>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={Styles.popper}>
                <Box sx={{ textAlign: 'center', marginBottom: '.5rem' }}>Indicadores SPI</Box>
                <Grid container spacing={2} sx={{ marginBottom: '.7rem' }}>
                  <Grid xs={2} item className={Styles.spi_indicators_item}>
                    <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.blue }} />
                    &gt; 1+
                  </Grid>
                  <Grid xs={3} item className={Styles.spi_indicators_item}>
                    <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.green }} />
                    &ge; 0.95 a 1
                  </Grid>
                  <Grid xs={4} item className={Styles.spi_indicators_item}>
                    <Box
                      className={Styles.phase_stats__item_circle_small}
                      sx={{ backgroundColor: SPI_COLORS.orange }}
                    />
                    &ge; 0.90 a &lt; 0.95
                  </Grid>
                  <Grid xs={3} item className={Styles.spi_indicators_item}>
                    <Box className={Styles.phase_stats__item_circle_small} sx={{ backgroundColor: SPI_COLORS.red }} />0
                    a &lt; 0.90
                  </Grid>
                  <Grid xs={1} item />
                  <Grid xs={4} item sx={{ textAlign: 'center', marginTop: '.5rem' }}>
                    <Box>Avance Programado</Box>
                    <LinearProgress
                      className={Styles.phase_stats__item_progress_bar}
                      sx={{ width: '100%' }}
                      color="secondary"
                      variant="determinate"
                      value={80}
                    />
                  </Grid>
                  <Grid xs={2} item />
                  <Grid xs={4} item sx={{ textAlign: 'center', marginTop: '.5rem' }}>
                    <Box>Avance Real</Box>
                    <LinearProgress
                      className={Styles.phase_stats__item_progress_bar}
                      sx={{
                        width: '100%',
                        backgroundColor: '#cee2e1',
                        '& .MuiLinearProgress-bar': { backgroundColor: '#082588' }
                      }}
                      variant="determinate"
                      value={80}
                    />
                  </Grid>
                  <Grid xs={1} item />
                </Grid>
                <Box className={Styles.phase_stats__container}>
                  <Box className={Styles.phase_stats__header}>Seguimiento de fase</Box>
                  <Box className={Styles.phase_stats__body}>
                    {loadingPhasesData ? (
                      <SkeletonLoading type="table" size={3} />
                    ) : (
                      phasesData.map((phase, index) => {
                        return (
                          <Box key={index} className={Styles.phase_stats__item}>
                            <Grid container>
                              <Grid item xs={4} className={Styles.phase_stats__item_name}>
                                <b>{phase.name}</b>
                              </Grid>
                              <Grid item xs={2} className={Styles.phase_stats__item_value}>
                                <small style={{ marginBottom: '.25rem' }}>
                                  {phase.estimated_percentage ? phase.estimated_percentage : 0}%
                                </small>
                                <small>{phase.weekly_percentage ? phase.weekly_percentage : 0}%</small>
                              </Grid>
                              <Grid item xs={4} className={Styles.phase_stats__item_progress}>
                                <LinearProgress
                                  className={Styles.phase_stats__item_progress_bar}
                                  color="secondary"
                                  variant="determinate"
                                  value={Number(phase.estimated_percentage)}
                                />
                                <LinearProgress
                                  className={Styles.phase_stats__item_progress_bar}
                                  sx={{
                                    backgroundColor: '#cee2e1',
                                    marginTop: '.5rem',
                                    '& .MuiLinearProgress-bar': { backgroundColor: '#082588' }
                                  }}
                                  variant="determinate"
                                  value={Number(phase.weekly_percentage)}
                                />
                              </Grid>
                              <Grid item xs={2} className={Styles.phase_stats__item_name}>
                                <Box
                                  className={Styles.phase_stats__item_circle}
                                  sx={{ backgroundColor: getSPIColor(phase.spi) }}
                                />
                                <Box sx={{ marginLeft: '.5rem' }}>{phase.spi}</Box>
                              </Grid>
                            </Grid>
                          </Box>
                        )
                      })
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <SkeletonLoading type="project" />
      )}
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default ProjectDetail

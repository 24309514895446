import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AuthService from 'api/services/auth.service'
import UserService from 'api/services/user.service'
import { userUpdatePasswordFormSchema } from 'utils/schemas/userFormSchema'
import Styles from './_ModalResetPassword.module.scss'

const ModalResetPassword = ({ open, selectedUser, onCancel, onConfirm }) => {
  const [loading, setLoading] = useState(false)
  const user = useSelector(store => store.userManagement.user)
  const handleSavePassword = async vals => {
    try {
      selectedUser ? await UserService.updatePassword(selectedUser.id, vals) : await AuthService.updatePassword(vals)
      setLoading(false)
      onConfirm('success')
    } catch (error) {
      console.log(error)
      onConfirm('error')
    }
  }
  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: { password: '', password_confirmation: '' },
    enableReinitialize: true,
    validationSchema: userUpdatePasswordFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSavePassword(vals)
    }
  })

  useEffect(() => {
    if (open) {
      if (selectedUser && user.role_id === 2) {
        onCancel()
      }
    }
  }, [open])

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
      <DialogTitle className={Styles.modal_header}>Actualizar contraseña</DialogTitle>
      <DialogContent sx={{ textAlign: 'center', paddingTop: '2rem !important' }}>
        {selectedUser && (
          <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', fontSize: '1.25rem !important' }}>
            <Box sx={{ fontSize: '1.25rem !important' }}>
              <b>Usuario:</b> {selectedUser.name} {selectedUser.last_name}
            </Box>
            <Box sx={{ fontSize: '1.25rem !important' }}>
              <b>Email:</b> {selectedUser.email}
            </Box>
          </Box>
        )}
        <Box>Actualiza tu contraseña escribiendo una contraseña nueva</Box>
        <br />
        <FormControl className={Styles.input}>
          <TextField
            name="password"
            label="Contraseña"
            placeholder="Ingresa una nueva contraseña"
            size="small"
            variant="outlined"
            type="password"
            value={values.password}
            error={touched?.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              startAdornment: <LockOutlinedIcon sx={{ marginRight: '.5rem' }} />
            }}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            name="password_confirmation"
            label="Contraseña"
            placeholder="Confirma la contraseña"
            size="small"
            variant="outlined"
            type="password"
            value={values.password_confirmation}
            error={touched?.password_confirmation && Boolean(errors.password_confirmation)}
            helperText={touched.password_confirmation && errors.password_confirmation}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              startAdornment: <LockOutlinedIcon sx={{ marginRight: '.5rem' }} />
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={Styles.modal_footer}>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
          Cancelar
        </Button>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="contained" onClick={handleSubmit}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalResetPassword.propTypes = {
  open: PropTypes.bool,
  selectedUser: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default ModalResetPassword

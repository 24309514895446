import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Switch, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProjectService from 'api/services/project.service'
import SideModal from 'components/SideModal/SideModal'
import { useCurrency } from 'hooks/useCurrency'
import { useInputValidations } from 'hooks/useInputValidations'
import Project from 'models/Project'
import { currencies } from 'utils/constants/currencies'
import { projectFormSchema } from 'utils/schemas/projectFormSchema'
import Styles from './_SideModalProject.module.scss'

const SideModalProject = ({ actionType, open, onCancel, onConfirm, selectedProject, isSubProject }) => {
  dayjs.extend(utc)
  const { formatStringCurrency, formatNumericCurrency } = useCurrency()
  const { validateNumericField, onKeyPressValidateDecimalNumber } = useInputValidations()

  const [loading, setLoading] = useState(false)
  const [project, setProject] = useState(new Project())

  const handleSaveProject = async values => {
    try {
      values = {
        ...values,
        days_deadline: dayjs(values.end_contractual_date).diff(dayjs(values.start_date), 'day'),
        amount: formatNumericCurrency(values.amount),
        amount_approved: formatNumericCurrency(values.amount_approved),
        costs: values.costs.map(cost => ({ ...cost, amount: formatNumericCurrency(cost.amount) }))
      }
      const projectSaved =
        actionType === 'create' ? await ProjectService.store(values) : await ProjectService.update(project.id, values)
      setLoading(false)
      onConfirm('success', projectSaved)
    } catch (error) {
      setLoading(false)
      onConfirm('error')
    }
  }
  const { errors, touched, values, handleBlur, handleSubmit, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: project,
    enableReinitialize: true,
    validationSchema: projectFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSaveProject(vals)
    }
  })

  const handleCurrencyOnBlur = (event, value, key, isExchange = false) => {
    handleBlur(event)
    setFieldValue(key, formatStringCurrency(value.replaceAll(',', ''), false, isExchange))
  }

  const handleAddCost = () => {
    const list = [...values.costs]
    list.push({ amount: '0.00', currency: 'MXN', exchange: '18.70' })
    setFieldValue('costs', list)
  }

  const handleRemoveCost = index => {
    const list = [...values.costs]
    list.splice(index, 1)
    setFieldValue('costs', list)
  }

  useEffect(() => {
    let amount = 0
    if (values.costs.length > 0) {
      values.costs.forEach(element => {
        if (Number(element.amount.replaceAll(',', '')) > 0 && Number(element.exchange.replaceAll(',', '') > 0)) {
          amount = (
            Number(amount) +
            Number(element.amount.replaceAll(',', '')) / Number(element.exchange.replaceAll(',', ''))
          ).toFixed(2)
        }
      })
    }
    const amountApproved = formatStringCurrency(Number(values.amount.replaceAll(',', '')) + Number(amount))
    setFieldValue('amount_approved', amountApproved)
  }, [values.costs, values.amount])

  useEffect(() => {
    // console.log(errors)
    // console.log(touched)
  }, [errors, touched])

  useEffect(() => {
    if (open) {
      if (actionType === 'update') {
        setProject({
          ...selectedProject,
          contract_number: selectedProject?.contract_number || '',
          large_description: selectedProject?.large_description || '',
          days_manually: selectedProject?.days_manually || '',
          amount: formatStringCurrency(selectedProject.amount),
          amount_approved: formatStringCurrency(selectedProject.amount_approved),
          costs: selectedProject.costs.map(cost => ({ ...cost, amount: formatStringCurrency(cost.amount) })),
          exchange: formatStringCurrency(selectedProject.exchange),
          start_date: dayjs(selectedProject.start_date),
          cutoff_date: dayjs(selectedProject.cutoff_date),
          end_contractual_date: selectedProject.end_contractual_date
            ? dayjs(selectedProject.end_contractual_date)
            : null,
          lpo_contractual_date: selectedProject.lpo_contractual_date
            ? dayjs(selectedProject.lpo_contractual_date)
            : null,
          show_lpo_dates: selectedProject.show_lpo_dates === 1
        })
        resetForm()
      } else {
        setProject({ ...new Project(), parent_id: isSubProject ? selectedProject?.id : null })
        resetForm()
      }
    }
  }, [open])

  return (
    <SideModal
      loading={loading}
      title={`${actionType === 'create' ? 'Nuevo' : 'Editar'} ${isSubProject ? 'Sub' : ''}Proyecto`}
      onConfirmText={`${actionType === 'create' ? 'Crear' : 'Guardar'} ${isSubProject ? 'Sub' : ''}Proyecto`}
      open={open}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      <>
        <FormControl className={Styles.input}>
          <TextField
            required
            label="Nombre del proyecto"
            name="name"
            size="small"
            variant="outlined"
            error={Boolean(touched.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            label="Número de contrato"
            name="contract_number"
            size="small"
            variant="outlined"
            value={values.contract_number}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            required
            label="Descripción corta"
            name="short_description"
            size="small"
            variant="outlined"
            error={Boolean(touched.short_description && errors?.short_description)}
            helperText={touched?.short_description && errors?.short_description}
            value={values.short_description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={Styles.input}>
          <TextField
            label="Descripción larga"
            name="large_description"
            size="small"
            variant="outlined"
            multiline
            rows={4}
            value={values.large_description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <Divider sx={{ height: '2px', marginBottom: '1.5rem', alignItems: 'center' }} textAlign="left">
          <Box sx={{ fontSize: '1.2rem' }}>Costos</Box>
        </Divider>
        {values.costs.length === 0 && <br />}
        {values.costs.map((cost, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <FormControl className={Styles.input} sx={{ marginRight: '.5rem' }}>
                <TextField
                  label="Monto"
                  name={`costs[${index}].amount`}
                  size="small"
                  variant="outlined"
                  value={cost.amount}
                  onBlur={e => handleCurrencyOnBlur(e, cost.amount, `costs[${index}].amount`)}
                  onChange={handleChange}
                  onKeyDown={onKeyPressValidateDecimalNumber}
                  helperText={`Equivalentes a: $ ${formatStringCurrency(
                    (Number(cost.amount.replaceAll(',', '')) / Number(cost.exchange)).toFixed(2)
                  )} ${values.currency}`}
                  InputProps={{
                    sx: { paddingRight: '0' },
                    endAdornment: (
                      <TextField
                        name={`costs[${index}].currency`}
                        sx={{
                          minWidth: '85px',
                          width: 'auto',
                          '& fieldset': { border: '0 !important' }
                        }}
                        size="small"
                        select
                        value={cost.currency}
                        SelectProps={{
                          IconComponent: props => <ExpandMoreIcon {...props} />,
                          renderValue: value => value
                        }}
                        onChange={handleChange}>
                        {currencies.map((option, index) => (
                          <MenuItem key={index} value={option.code} disabled={option.code === values.currency}>
                            {option.code} - {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ),
                    startAdornment: <span>$&nbsp;</span>
                  }}
                />
              </FormControl>
              <FormControl className={Styles.input} sx={{ width: '75% !important' }}>
                <TextField
                  label={`Tipo de cambio a ${values.currency}`}
                  name={`costs[${index}].exchange`}
                  size="small"
                  variant="outlined"
                  value={cost.exchange}
                  onBlur={e => handleCurrencyOnBlur(e, cost.exchange, `costs[${index}].exchange`, true)}
                  onChange={handleChange}
                  onKeyDown={onKeyPressValidateDecimalNumber}
                  InputProps={{
                    endAdornment: cost.currency,
                    startAdornment: <span>$&nbsp;</span>
                  }}
                />
              </FormControl>
              <IconButton onClick={() => handleRemoveCost(index)}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>
          )
        })}
        <Divider sx={{ height: '2px', marginBottom: '2.5rem', alignItems: 'center' }} textAlign="center">
          <Button variant="contained" onClick={handleAddCost}>
            Agregar monto
          </Button>
        </Divider>
        <Box sx={{ display: 'flex' }}>
          <FormControl className={Styles.input} sx={{ marginRight: '.5rem' }}>
            <TextField
              label={`Monto en ${values.currency}`}
              name="amount"
              size="small"
              variant="outlined"
              value={values.amount}
              onBlur={e => handleCurrencyOnBlur(e, values.amount, 'amount')}
              onChange={handleChange}
              onKeyDown={onKeyPressValidateDecimalNumber}
              InputProps={{
                sx: { paddingRight: '0' },
                endAdornment: (
                  <TextField
                    name="currency"
                    sx={{
                      minWidth: '85px',
                      width: 'auto',
                      '& fieldset': { border: '0 !important' }
                    }}
                    size="small"
                    select
                    value={values.currency}
                    SelectProps={{
                      IconComponent: props => <ExpandMoreIcon {...props} />,
                      renderValue: value => value
                    }}
                    onChange={handleChange}>
                    {currencies.map((option, index) => (
                      <MenuItem key={index} value={option.code}>
                        {option.code} - {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ),
                startAdornment: <span>$&nbsp;</span>
              }}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              disabled
              required
              label={`Monto aprobado en ${values.currency}`}
              size="small"
              variant="outlined"
              value={values.amount_approved}
              error={Boolean(touched.amount_approved && errors?.amount_approved)}
              helperText={touched?.amount_approved && errors?.amount_approved}
              InputProps={{
                endAdornment: values.currency,
                startAdornment: <span>$&nbsp;</span>,
                readOnly: true
              }}
            />
          </FormControl>
        </Box>
        <Divider sx={{ height: '2px', marginBottom: '1.5rem', alignItems: 'center' }} textAlign="left">
          <Box sx={{ fontSize: '1.2rem' }}>Fechas</Box>
        </Divider>
        <Box sx={{ display: 'flex' }}>
          <FormControl className={Styles.input}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de inicio"
                format="DD/MM/YYYY"
                value={values.start_date}
                onChange={value => setFieldValue('start_date', value)}
                slotProps={{
                  textField: {
                    required: true,
                    size: 'small',
                    error: Boolean(touched.start_date && errors?.start_date),
                    helperText: touched?.start_date && errors?.start_date,
                    onBlur: handleBlur
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl className={Styles.input} sx={{ marginLeft: '.5rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha de corte"
                format="DD/MM/YYYY"
                disabled={values.start_date === null}
                minDate={dayjs(values.start_date)}
                maxDate={dayjs(values.start_date).add(1, 'week')}
                value={values.cutoff_date}
                onChange={value => setFieldValue('cutoff_date', value)}
                slotProps={{
                  textField: {
                    required: true,
                    size: 'small',
                    error: Boolean(touched.cutoff_date && errors?.cutoff_date),
                    helperText: touched?.cutoff_date && errors?.cutoff_date,
                    onBlur: handleBlur
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        <FormControl className={Styles.input}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Fecha final contractual"
              format="DD/MM/YYYY"
              disabled={values.start_date === null}
              minDate={dayjs(values.start_date).add(6, 'weeks')}
              value={values.end_contractual_date > values.start_date ? values.end_contractual_date : null}
              onChange={value => {
                setFieldValue('end_contractual_date', value)
                setFieldValue('days_deadline', value.diff(values.start_date, 'days'))
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  required: true,
                  error: Boolean(touched.end_contractual_date && errors?.end_contractual_date),
                  helperText:
                    (touched?.end_contractual_date && errors?.end_contractual_date) ||
                    'Nota: El plazo mínimo es de 6 semanas',
                  onBlur: handleBlur
                }
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <Box sx={{ display: 'flex' }}>
          <FormControl className={Styles.input}>
            <TextField disabled label="Plazo en días" size="small" variant="outlined" value={values.days_deadline} />
          </FormControl>
          <FormControl className={Styles.input} sx={{ marginLeft: '.5rem' }}>
            <TextField
              label="Plazo en días manual"
              size="small"
              variant="outlined"
              value={values.days_manually}
              onChange={e => {
                if (validateNumericField(e.target.value) && values.days_deadline >= Number(e.target.value))
                  setFieldValue('days_manually', e.target.value)
              }}
              helperText={`Valor máximo: ${values.days_deadline}`}
            />
          </FormControl>
        </Box>
        <FormControl className={Styles.input}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>Mostrar Hitos de fechas "{values.lpo_dates_label}"</Box>
            <Switch
              name="show_lpo_dates"
              checked={values.show_lpo_dates}
              onChange={e => [handleChange(e), e.target.checked && setFieldValue('lpo_contractual_date', null)]}
            />
          </Box>
        </FormControl>
        {values.show_lpo_dates && (
          <FormControl className={Styles.input}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={`Fecha ${values.lpo_dates_label} contractual`}
                format="DD/MM/YYYY"
                disabled={values.start_date === null}
                minDate={dayjs(values.start_date).add(1, 'day')}
                maxDate={values.end_contractual_date}
                value={
                  values.lpo_contractual_date > values.start_date &&
                  values.lpo_contractual_date < values.end_contractual_date
                    ? values.lpo_contractual_date
                    : null
                }
                onChange={value => setFieldValue('lpo_contractual_date', value)}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                    error: Boolean(touched.lpo_contractual_date && errors?.lpo_contractual_date),
                    helperText: touched?.lpo_contractual_date && errors?.lpo_contractual_date,
                    onBlur: handleBlur
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      </>
    </SideModal>
  )
}

SideModalProject.propTypes = {
  actionType: PropTypes.string,
  isSubProject: PropTypes.bool,
  open: PropTypes.bool,
  selectedProject: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalProject

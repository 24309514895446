import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, TextField } from '@mui/material'
import UserService from 'api/services/user.service'
import DataTable from 'components/DataTable/DataTable'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import User from 'models/User'
import ModalResetPassword from 'components/ModalResetPassword/ModalResetPassword'
import SideModalUser from './components/SideModalUser'

const Users = () => {
  const authUser = useSelector(store => store.userManagement.user)

  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [actionType, setActionType] = useState()
  const [loading, setLoading] = useState(false)
  const [showSideModalUser, setShowSideModalUser] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModalResetPassword, setShowModalResetPassword] = useState(false)

  const [selectedUser, setSelectedUser] = useState(new User())
  const [usersList, setUsersList] = useState([])

  const handleActions = (type, value) => {
    setSelectedUser({ ...value, phone: value.phone || '' })
    setActionType(type)
    if (type === 'edit') {
      setShowSideModalUser(true)
    } else if (type === 'delete') {
      setShowDeleteModal(true)
    } else if (type === 'reset_password') {
      setSelectedUser(value)
      setShowModalResetPassword(true)
    }
  }

  const headers = [
    {
      header: 'Email',
      key: 'email',
      cellType: 'text'
    },
    {
      header: 'Nombre',
      key: 'name',
      cellType: 'text'
    },
    {
      header: 'Apellidos',
      key: 'last_name',
      cellType: 'text'
    },
    {
      header: 'Teléfono fijo',
      key: 'phone',
      cellType: 'text'
    },
    {
      header: 'Teléfono celular',
      key: 'cellphone',
      cellType: 'text'
    },
    {
      header: 'Rol Interno',
      key: 'role',
      cellType: 'text'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: authUser?.role_id !== 2 ? 'action_buttons_reset_password' : 'action_buttons',
      action: handleActions
    }
  ]

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        let value = element[header.key]
        if (header.key === 'role') {
          value = element[header.key].name
        }
        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getUsers = async () => {
    try {
      const users = await UserService.findAll()
      setUsersList(orderData(users))
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Users, try again later'
      })
    }
  }

  const handleSaveUser = result => {
    setShowSideModalUser(false)
    if (result === 'success') {
      setAlert({ open: true, severity: 'success', message: 'Usuario guardado con èxito' })
      getUsers()
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Algo saliò mal al intentar guardar el Usuario, inténtalo mas tarde'
      })
    }
  }

  const handleCreateUser = () => {
    setActionType('create')
    setShowSideModalUser(true)
    setSelectedUser(new User())
  }

  const handleDeleteUser = async () => {
    setLoading(true)
    try {
      await UserService.delete(selectedUser.id)
      setLoading(false)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'success', message: 'User deleted with success' })
      getUsers()
    } catch (error) {
      setLoading(false)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while deleting User, try again later' })
    }
  }

  const handleUpdatePassword = result => {
    setShowModalResetPassword(false)
    if (result === 'success') {
      setAlert({ open: true, severity: 'success', message: 'Contraseña actualizada con èxito' })
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Algo saliò mal al intentar guardar la contraseña, inténtalo mas tarde'
      })
    }
  }

  useEffect(() => {
    if (authUser) {
      getUsers()
    }
  }, [authUser])

  return (
    <>
      <h1>Usuarios Internos</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" disabled />
        <Button variant="contained" color="primary" onClick={handleCreateUser}>
          Nuevo Usuario
        </Button>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable headers={headers} data={usersList} />
      </Box>
      <SideModalUser
        actionType={actionType}
        open={showSideModalUser}
        selectedUser={selectedUser}
        onCancel={() => setShowSideModalUser(false)}
        onConfirm={handleSaveUser}
      />
      {showModalResetPassword && (
        <ModalResetPassword
          open={showModalResetPassword}
          selectedUser={selectedUser}
          onCancel={() => setShowModalResetPassword(false)}
          onConfirm={handleUpdatePassword}
        />
      )}
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <ModalDelete
        element={selectedUser}
        open={showDeleteModal}
        loading={loading}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteUser}
      />
    </>
  )
}

export default Users

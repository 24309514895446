import * as Yup from 'yup'

export const entityCreationFormSchema = Yup.object().shape({
  entity: Yup.string().required('El nombre de tu empresa es requerido'),
  name: Yup.string().required('El nombre es requerido'),
  last_name: Yup.string().required('Los apellidos son requeridos'),
  email: Yup.string().email('Email inválido').required('Email es requerido'),
  password: Yup.string().required('La contraseña es requerida'),
  password_confirmation: Yup.string()
    .required('La confirmación es requerida')
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
})

export const userCreationFormSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  last_name: Yup.string().required('Los apellidos son requeridos'),
  email: Yup.string().email('Email inválido').required('Email es requerido'),
  password: Yup.string().required('La contraseña es requerida'),
  password_confirmation: Yup.string()
    .required('La confirmación es requerida')
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
})

export const userUpdateFormSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  last_name: Yup.string().required('Los apellidos son requeridos'),
  email: Yup.string().email('Email inválido').required('Email es requerido')
})

export const userUpdatePasswordFormSchema = Yup.object().shape({
  password: Yup.string().required('La contraseña es requerida'),
  password_confirmation: Yup.string()
    .required('La confirmación es requerida')
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
})

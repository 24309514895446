import * as Yup from 'yup'

export const projectFormSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  short_description: Yup.string().required('La descripción corta es requerida'),
  start_date: Yup.date().required('La fecha de inicio es requerida'),
  cutoff_date: Yup.date().required('La fecha de corte es requerida'),
  end_contractual_date: Yup.date().required('La fecha final es requerida'),
  show_lpo_dates: Yup.boolean(),
  lpo_contractual_date: Yup.date().when('$show_lpo_dates', {
    is: true,
    then: schema => schema.required('La fecha LPO es requerida')
  }),
  amount_approved: Yup.string()
    .required('El monto aprobado es requerido')
    .test('is-cero', 'El monto aprobado no puede ser 0', value => value !== '0.00')
})

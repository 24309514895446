import download from 'downloadjs'
import axios from '../axios'

class ProjectService {
  static async store(params) {
    const resp = await axios.post('/projects', params)
    return resp.data
  }

  static async find(id) {
    const resp = await axios.get(`/projects/${id}`)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/projects')
    return resp.data
  }

  static async findAllByUser() {
    const resp = await axios.get('/projects/user')
    return resp.data
  }

  static async findPhases(id) {
    const resp = await axios.get(`/projects/${id}/phases`)
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/projects/${id}`, { ...params })
    return resp.data
  }

  static async updateDatesLabels(id, params) {
    const resp = await axios.put(`/projects/${id}/dates_labels`, { ...params })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/projects/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/projects/restore/${id}`)
    return resp.data
  }

  /** WEEKS */
  static async addWeek(id, params) {
    const resp = await axios.put(`/projects/${id}/add_week`, { ...params })
    return resp.data
  }

  static async deleteWeek(id, params) {
    const resp = await axios.put(`/projects/${id}/delete_week`, { ...params })
    return resp.data
  }

  /** ROLES */
  static async findRoles() {
    const resp = await axios.get(`/projects/roles`)
    return resp.data
  }

  /** USERS */
  static async updateUsers(id, params) {
    const resp = await axios.put(`/projects/${id}/users`, { ...params })
    return resp.data
  }

  /** RISKS */
  static async findRisks(id) {
    const resp = await axios.get(`/projects/${id}/risks`)
    return resp.data
  }

  static async findRisksFilters(id, params) {
    const resp = await axios.get(`/projects/${id}/risks/filters`, { params: { ...params } })
    return resp.data
  }

  static async downloadRisksReport(id, params) {
    const resp = await axios.get(`/projects/${id}/risks/download`, {
      params: { ...params },
      responseType: 'blob',
      headers: {
        Accept: params.format === 'pdf' ? 'application/pdf' : 'text/csv'
      }
    })
    const content = resp.headers['content-type']
    if (params.format === 'pdf') {
      download(resp.data, `Reporte de riesgos PDF`, content)
    } else {
      download(resp.data, `Reporte de riesgos CSV`, content)
    }

    return resp.data
  }

  static async findRisksProbabilitiesData(id) {
    const resp = await axios.get(`/projects/${id}/risks/probabilities`)
    return resp.data
  }

  static async findRisksCostImpactsData(id) {
    const resp = await axios.get(`/projects/${id}/risks/cost_impacts`)
    return resp.data
  }

  static async findRisksTimeImpactsData(id) {
    const resp = await axios.get(`/projects/${id}/risks/time_impacts`)
    return resp.data
  }

  static async findRisksStats(id) {
    const resp = await axios.get(`/projects/${id}/risks/stats`)
    return resp.data
  }

  static async updateRiskSettings(id, params) {
    const resp = await axios.put(`/projects/${id}/risks/settings`, { ...params })
    return resp.data
  }
}

export default ProjectService
